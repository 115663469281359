export const pagewhatsapp = {
    p1: `Welcome WhatsApp`,
    p2: `Offer a personalized welcome message via WhatsApp at the end of
    the check-in process and make your guests feel valued and
    special from the very beginning.`,
    p3: `Get Started`,
    p4: `Differentiate yourself from your competition and increase customer satisfaction and loyalty with our innovative service!`,
    p5: `Advantages of using WhatsApp in the check-in process.`,
    p6: `Offering a personalized welcome message via WhatsApp creates
    a unique and special experience for each guest.`,
    p7: `Offer added value to your guests and differentiate yourself from the competition,
    improving customer satisfaction.`,
    p8: `Reduce the time and resources required to check in,
    automating the process and offering a more efficient and
    faster experience for your guests.`,
    p9: `By offering an innovative and convenient service, guest satisfaction
    and loyalty are improved, which can have a positive impact on
    the reputation and success of your properties.`,
    p10: `Get Started`,
    p11: `Revolutionize your check-in process`,
    p12: `Transform your guests' experience and increase the success of your business.`,
    p13: `Guests will automatically receive a personalized welcome message,
    You have the opportunity to add a special touch by customizing the message
    for each of them.`,
    p14: `Terms and
    conditions`,
    p15: ``,
    p16: ``,

}