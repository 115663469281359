export const precios_update = {
  text1: "Payment Method",
  text2: "Credit Card",
  text3: "No payment methods registered",
  text4: "Edit your payment method",
  text5: "Add payment method",
  text6: "Add a payment method",
  text7: "Update a payment method",
  text8: "Register or update the payment method",
  text9: "Card number:",
  text10: "Exp",
  text11: "CVC",
  text12: "Save card",
  text13: "Invalid number",
  text14: "Invalid",
  text15: "Invalid CVC",
  text16: "Something went wrong",
  text17: "Consumption",
  text18: "My invoices",
  text19: "From - To",
  text20: "ID",
  text21: "Amount",
  text22: "Invoice issuer",
  text23: "BIG NERDS COMPANY L.L.C.",
  text24: "Issue date",
  text25: "Status",
  text26: "Download Invoice",
  text27: "Generating Invoice ...",
  text28: "Almost done, please wait ...",
  text29: "Loading ...",
  text30: "Sorry, the invoice could not be generated",
  text31: "Are you sure you want to delete the payment method?",
  text32: "You will lose saved data",
  text33: "Delete method",
  text34: "Cancel",


  // Corrections 
  p1: "Subscription",
  p2: "Your Subscription",
  p3: "FREE",
  p4: "CUSTOM",
  p5: "You don't have an active subscription. Let's do something about it!",
  p6: "Expires: ",
  p7: "Choose a plan",
  p8: "Modify your subscription",
  p9: "Number of properties",
  p10: "Per property",
  p11: "Manage subscription",
  p12: "Payment settings",
  p13: "Expires: ",
  p14: "Edit",
  p15: "Auto-renewal",
  p16: "Enabled",
  p17: "Next payment is scheduled on",
  p18: "Invoices and receipts",
  p19: "CUSTOM",
  p20: "View invoice",
  p21: "Download invoice",
  p22: "by",


  p23: "Monthly",
  p24: "Yearly",
  p25: "We give you 2 months free",
  p26: "Subscription Type",

  p27: "Tourist Apartment",
  p28: "Hotel - Hostel",
  p29: "Camping",
  p30: "Villa",

  p31: `The <strong>"property"</strong> refers to individual houses or apartments.
    For <strong>"aparthotels"</strong>, each apartment counts as a single
    property.`,
  p32: `Counts as
  <strong>Rooms</strong> in the case of hotels, as beds in the case of hostels,
  and as <strong>people</strong> in the case of camping.

  <br />
  <strong>For example: </strong>A hostel with 4 rooms and 20 beds will count as
  20 lodging units`,


  p33: "PREMIUM",
  p34: "month",
  p35: "room",
  p36: "apartment",
  p37: "Plan details",
  p38: "In-person check-in via QR",
  p39: "Check-in via pre-sent link",
  p40: "Welcome email message",
  p41: "Biometric policy signature",
  p42: "ID - Passport scanner",
  p43: "Unlimited cloud storage",
  p44: "Cross-sell store",
  p45: "Automatic submission of reports to authorities",
  p46: "PMS booking synchronization",
  p47: "Update subscription",
  p48: "Continue",
  p49: "by",

  p50: "Select additional services for your plan",
  p51: "Recommended",
  p52: "WhatsApp",
  p53: "Invite your guests to check in via scheduled WhatsApp before they arrive at your property.",
  p54: "apartment",
  p55: "villa",
  p56: "spot",
  p57: "room",
  p58: "Difference in using WhatsApp",
  p59: "You use our WhatsApp number.",
  p60: "The name that will appear to the guest when receiving messages will be WACheckin. There is no additional cost. This service is only available for accommodations with up to 50 spots.",
  p61: "The name that will appear when sending messages will be your WhatsApp Business, you can customize your WhatsApp profile and even verify and have your green verification badge. The sending cost will be subject to your WhatsApp Business usage with Meta. Currently, the cost is 0.035 per conversation (check-in) and you pay directly to Meta.",
  p62: "You use your own WhatsApp number",
  p63: "Reviews",
  p64: "Take advantage of this opportunity to enhance your guests' experience and receive more positive reviews!",
  p65: "Benefits",
  p66: "At the end of the stay, we will send your guests a review test via WhatsApp, significantly boosting your property's online reputation. Our service is designed to increase your positive reviews.",
  p67: "Remote access",
  p68: "We send your guests a virtual key to access the property independently.",
  p69: "Benefits",
  p70: "Your guests will receive the access key to their room securely and conveniently once they have completed their check-in, providing a hassle-free experience and improving access management efficiency.",
  p71: "Identity verification",
  p72: "We verify your guests' identity using our biometric comparison software",
  p73: "Benefits",
  p74: "We verify your guests' identity using our biometric comparison software, ensuring that their documentation matches the person accessing your property.",
  p75: "SUBSCRIBE NOW",
  p76: "Unlimited cloud storage",
  p77: "Custom",
  p78: "Ask our sales team",
  p79: "WhatsApp",
  p80: "Identity Verification",
  p81: "Check-in Reviews",
  p82: "API Connection for Smart Locks",
  p83: "Card Payment (for deposits, services, etc.)",
  p84: "Connect with sales",
  p85: "Start",
  p86: "",
  p87: "",
  p88: "",
  p89: "",
};
