<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalContacto"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" style="color: #000">
        <div class="contenedor-loader d-flex flex-column" v-if="loader">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <br />
          <strong>Enviando .. . </strong>
        </div>

        <div class="modal-header">
          <img
            src="/logo.png"
            class="img-fluid"
            alt=""
            v-animate-css="'zoomIn'"
            style="width: 187px"
          />

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="modal-body" style="text-align: left">
          <div class="contai" v-if="!enviado">
            <div class="d-flex flex-row">
              <h5 class="modal-title" id="exampleModalLabel">CONTACTA CON QRCHECKIN</h5>
              <!-- 
            <div>
              <img src="/astro4.svg" alt="" style="width: 100%; height: 100px" />
            </div> -->
            </div>

            <hr />

            <div class="formulario">
              <div class="form-group">
                <label for="">{{ $t("home.p46") }}</label>
                <input type="text" class="form-control" v-model="nombre" />
              </div>

              <div class="form-group">
                <label for="">{{ $t("home.p47") }}</label>
                <input type="email" class="form-control" v-model="email" />
              </div>

              <div class="form-group">
                <label for="">{{ $t("home.p48") }}</label>
                <input type="number" class="form-control" v-model="telefono" />
              </div>

              <div class="form-group">
                <label for="">{{ $t("home.p49") }} </label>
                <textarea
                  name=""
                  class="form-control"
                  id=""
                  cols="30"
                  rows="5"
                  v-model="asunto"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="contai" v-else v-animate-css="'fadeIn'">
            <img
              src="/astro6.svg"
              alt=""
              class="img-fluid"
              style="height: 150px; margin: auto; display: block"
            />
            <br />
            <div style="font-size: 20px; font-weight: 500">
              {{ $t("home.p50") }}
            </div>
            <br />
            <p>{{ $t("home.p51") }}</p>
          </div>

          <div class="custom-control custom-checkbox">
            <input
              v-model="terminosinput"
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
            />
            <label class="custom-control-label" for="customCheck1"
              >Acepto los

              <a @click.prevent="terminos()" style="color: blue; font-weight: 600"
                >Términos y Condiciones</a
              >
            </label>
          </div>
        </div>

        <!-- <div class="form-check" style="margin-top: 18px">
          <label class="form-check-label" for="flexCheckDefault">
            Acepto los
            <a
              href="https://app.wacheckin.com/politica-de-privacidad-de-alojamientos"
              target="_blank"
              style="color: blue; font-weight: 600"
              >términos y condiciones</a
            >
          </label>
        </div> -->

        <div class="modal-footer" v-if="!enviado">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ $t("home.p52") }}
          </button>
          <button
            @click.prevent="enviarPeticion()"
            type="button"
            class="btn"
            style="color: white; font-weight: 600"
            :style="{ background: '#11b14d' }"
          >
            {{ $t("home.p53") }} <i class="fas fa-paper-plane ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nombre: null,
      telefono: null,
      email: null,
      tipo: null,
      numero: null,
      asunto: null,
      llego: null,
      loader: false,
      enviado: false,
      terminosinput: false,
    };
  },
  methods: {
    terminos() {
      this.$router.push({ name: "Terminos" });
      $("#modalContacto").modal("hide");
    },
    enviarPeticion() {
      if (!this.nombre) {
        toastr.error(this.$t("home.p39"));
        return;
      }
      if (!this.email) {
        toastr.error(this.$t("home.p42"));
        return;
      }
      if (!this.telefono) {
        toastr.error(this.$t("home.p41"));
        return;
      }

      if (!this.asunto) {
        toastr.error(this.$t("home.p54"));
        return;
      }
      if (!this.terminosinput) {
        toastr.error("Debe aceptar los términos y condiciones");
        return;
      }

      this.loader = true;
      var url = "https://api.qrcheckin.pro/api/contacto/reserva";
      const datos = {
        nombre: this.nombre,
        telefono: this.telefono,
        email: this.email,
        asunto: this.asunto,
      };
      axios
        .post(url, datos)
        .then((res) => {
          this.loader = false;
          this.enviado = true;
          this.nombre = null;
          this.telefono = null;
          this.email = null;
          this.asunto = null;
        })
        .catch((e) => {
          this.loader = false;
          toastr.error(this.$t("home.p55"));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .modal-body {
    padding: 30px 88px;
  }
}

.contenedor-loader {
  position: absolute;
  background: rgb(232 232 232 / 64%);
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4caf50 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control {
  min-height: 50px;
  background: #f7f7f7;
}
</style>
