export const nav = {
    p1: `Funciones`,
    p2: `Integraciones`,
    p3: `Precios`,
    p4: `Ver video`,
    p5: `Regístrate`,
    p6: `Iniciar`,
    
	
	p7: `QR Checkin`,
    p8: `Tus huéspedes podrán hacer checkin a la llegada mediante el escaneo del código QR de tu alojamiento.`,
    
	p9: `Checkin Online`,
    p10: `Tus huéspedes recibirán un enlace para hacer checkin antes de que lleguen a tu alojamiento.`,
    

	p11: `WhatsApp Bienvenida`,
    p12: `Ofrece un mensaje de bienvenida a través de WhatsApp con la información del checkin de forma automática.`,

    p13: `Venta Cruzada`,
    p14: `Tus huéspedes tendrán en su whatsapp acceso a los productos, servicios o tours que ofreces en tu alojamiento.`,

    p15: `Envío a autoridades`,
    p16: `Enviamos de forma automática los partes de viajeros a las autoridades locales.`,

    p17: `Mensajes automáticos`,
    p18: `Establece mensajes automáticos que se envirán en horas concretas: (ex. Envia la carta del restaurante a las 11AM.)`,
    p21: ``,
    p22: ``,
}


