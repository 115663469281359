export const selfcheckin = {
    p1: `Check-in Automático`,
    p2: `Esqueça a entrega de chaves pessoalmente e poupe centenas de horas com a nossa funcionalidade de check-in automático, eliminando a necessidade de se deslocar até à propriedade.`,
    p3: `Comece Agora`,
    p4: `Consegue imaginar nunca mais ter que se deslocar para entregar chaves nas suas propriedades?`,
    p5: `Os seus hóspedes desfrutarão da conveniência e autonomia do check-in automático`,
    p6: `Esqueça-se de se adaptar aos horários de chegada dos seus hóspedes.`,
    p7: `O registo de hóspedes e o acesso são totalmente automáticos.`,
    p8: `Melhore a experiência dos seus hóspedes com horários de chegada flexíveis.`,
    p9: `Diga adeus às perdas de chaves físicas.`,
    p10: `Gerencie múltiplas reservas no mesmo dia sem complicações.`,
    p11: `Começar`,
    p12: `Descubra a revolução do check-in automático.`,
    p13: `Vantagens do Check-in Automático`,
    p14: `Ao utilizar o check-in automático, os viajantes podem poupar tempo e evitar as longas filas que costumam formar-se nos terminais de aeroportos e hotéis. Além disso, esta ferramenta permite uma maior flexibilidade no processo de check-in, já que pode ser realizado a qualquer momento e em qualquer lugar, desde que haja uma conexão à internet. Em resumo, o check-in automático é uma excelente opção para aqueles viajantes que procuram uma experiência mais confortável, eficiente e flexível nas suas viagens.`,
    p15: `Termos e
    condições`,
    p16: ``,
    p17: ``,
}
