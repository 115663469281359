export const mensajeautomatico = {
    p1: `Mensagens Automáticas..`,
    p2: `Torne o seu Hotel Inesquecível com Mensagens Personalizadas e Programadas,`,
    p3: `Otimiza o teu Check-in Agora`,
    p4: `"Mensagens Personalizadas, Chegadas Memoráveis: A Magia do Autocheck-in com QRCHECKIN`,
    p5: `Descobre como podes oferecer aos teus visitantes uma experiência sem complicações`,
    p6: `Autocheckin Via WhatsApp`,
    p7: `Mensagens Automáticas Programáveis`,
    p8: `Informações sobre Serviços do Hotel`,
    p9: `Personalização Sob Medida`,
    p10: `Lembretes automáticos via WhatsApp`,
    p11: `Começar`,
    p12: `Adapta cada mensagem à identidade única do teu hotel`,
    p13: `Desde a receção até aos detalhes específicos`,
    p14:`Facilitamos o processo de chegada ao permitir que os teus hóspedes façam o check-in 
    com um simples link via WhatsApp. Elimina filas e oferece uma entrada rápida e confortável.`,
    p15:``,
}
