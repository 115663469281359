export const police = {
    p1: `Police Reporting`,
    p2: `Comply with the rules and regulations of your country automatically with our check-in sending system to the police!`,
    p3: `Get Started`,
    p4: `Automatic sending to the relevant authorities`,
    p5: `Advantages of using our automatic service`,
    p6: `Our system seamlessly integrates with your existing property management system, meaning there's no need to change your current processes.`,
    p7: `In case of an emergency or security situation, the immigration police will have immediate access to your guests' check-ins, which can help expedite their response and protect your guests.`,
    p8: `By providing important information to the immigration police, our system helps keep your guests safe and protected during their stay at your property`,
    p9: `Our automated check-in sending service to the immigration police will help you comply with local regulations, avoiding potential fines or penalties.`,
    p10: `Get Started`,
    p11: `Save time and hassle`,
    p12: `Automatic sending of traveler reports to the authorities`,
    p13: `It works as follows: Once your guests have completed the
    online check-in, we generate traveler reports and automatically send them
    to the competent authorities within 24 hours of the check-in date,
    as required by law.`,
    p14: `Terms and
    conditions`,
    p15: ``,
    p16: ``,
    p17: ``,
    p18: ``,
    p19: ``,

}