export const upselling = {
    p1: `Cross-Selling`,
    p2: `Increase your profits by offering personalized experiences to your guests, whether they are your own or third-party.`,
    p3: `Try it for free`,
    p4: `Can you imagine never having to travel to deliver keys to your properties again?`,
    p5: `Take your business to another level with our cross-selling system`,
    p6: `Enhance your guests' experience`,
    p7: `Include and customize as many of your own experiences as you want`,
    p8: `Offer your guests a wide range of third-party experiences such as Civitatis, Viator, and others`,
    p9: `Increase your profits with each reservation`,
    p10: `Add value to your tourist accommodation proposal`,
    p11: `Happy guests, better reviews`,
    p12: `Try it for free`,
    p13: `Terms and
    Conditions`,
    p14: ``,
    p15: ``,
    p16: ``,
    p17: ``,
    p18: ``,
    p19: ``,
    p20: ``,
    p21: ``,

}