export const qrcheckin = {
    p1: `QR Check-in`,
    p2: `Eliminate long waits at reception. With our online check-in system, your guests can complete the process from the comfort of their device.`,
    p3: `Try for free`,
    p4: `Discover the convenience and efficiency of online check-in`,
    p5: `We are here to transform the way your guests start their experience at your hotel`,
    p6: `No Lines, No Waits`,
    p7: `Instant Access via WhatsApp`,
    p8: `Total Customization`,
    p9: `Two-step check-ins, easy and fast`,
    p10: `Scheduled Automatic Messages`,
    p11: `Get Started`,
    p12: `Simplify your guests' arrival`,
    p13: `Start building memorable experiences from the very beginning`,
    p14: `Allow your guests to check in quickly and hassle-free via WhatsApp link`,
    p15:'Welcome to the Stress-Free Arrival Future',

}