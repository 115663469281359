export const politicas = {

    p1: `Política de privacidade de alojamentos`,
    p2: `O enquadramento normativo geral é o RGPD europeu 679/2016 sobre proteção de dados pessoais.`,
    p3: `1. Geral`,
    p4: `Para habilitar o uso da aplicação QRCheckin e das restantes plataformas em que são oferecidos os serviços de QRCheckin, é necessário ser utilizador registado da mesma e aceitar as regras de privacidade que se detalham a seguir para tal efeito, de acordo com o estabelecido no Regulamento Europeu de Proteção de Dados (Regulamento 2016/679) e as leis específicas do país e da região onde se encontra o sujeito dos dados a tratar.`,
    p5: `Ao utilizar os serviços de QRCheckin, e consequentemente ao aceitar a sua política de privacidade, você concorda que o proprietário do alojamento, responsável pelos seus dados pessoais, armazene e processe os dados pessoais que você nos fornece com o objetivo de utilizar a plataforma. Em todo caso, é informado ao hóspede que os seguintes tratamentos são realizados com base nas informações fornecidas.`,
    p6: `2. O que fazemos com seus dados?`,
    p7: `Os tratamentos, e portanto, o uso de dados pessoais que são realizados são os seguintes:`,
    p8: `-Gestão de estadias, com o objetivo de controlar a estadia nos alojamentos.`,
    p9: `-Informação às Autoridades, conforme requerido pelas normativas turísticas, já que pode existir obrigação legal de o fazer.`,
    p10: `3. Que dados pessoais tratamos na aplicação?`,
    p11: `Os seguintes dados poderiam ser processados (existem algumas regiões onde a recolha de alguns dados não é obrigatória) para administrar a sua estadia e enviar informações às Autoridades: Nome, Apelido, Segundo Apelido, Sexo, Nacionalidade, Nacionalidade, Data de Nascimento, País de Nascimento, Cidade de Nascimento, País de Residência, Cidade de Residência, Endereço de Residência, Tipo de Identificação, Número de Identificação, Data de Identificação, País de Identificação, Cidade de Emissão, Propósito da Estadia, Número de Visto, Assinatura.`,
    p12: `4. Por quanto tempo são armazenados?`,
    p13: ` Em geral, armazenamos a informação durante o tempo necessário para realizar os diferentes tratamentos, e pelo menos, de acordo com a normativa turística, por um período de 3 anos.`,
    p14: `5. A quem transferimos os dados?`,
    p15: `Qualquer dado que seja recolhido pelo titular do alojamento, em nenhum caso, será cedido a terceiras empresas, exceto nos casos que se detalham a seguir:`,
    p16: `-Todos os tratamentos anteriormente mencionados são cedidos exclusivamente ao QRCheckin para os efeitos da gestão completa do registo de hóspedes e demais serviços acordados entre QRCheckin e o titular do alojamento.`,
    p17: `-A transferência de tratamentos às Autoridades.`,
    p18: `6. Tomamos decisões automatizadas?`,
    p19: `Não, atualmente não estão a ser tomadas decisões de tratamento automatizadas.`,
    p20: `7. Direitos de acesso, retificação, supressão, limitação do tratamento, portabilidade de dados e oposição.`,
    p21: `De acordo com o Regulamento Geral de Proteção de Dados, juntamente com o seu direito de se opor à automatização de decisões, qualquer utilizador que tenha fornecido dados ao titular do alojamento tem os seguintes direitos que pode exercer:`,
    p22: `-Acesso.<br />-Retificação.<br />-Supressão.<br />-Limitação de tratamento.<br />-Portabilidade dos dados.<br />-Oposição.`,
    p23: `O exercício destes direitos deverá ser feito mediante comunicação dirigida ao titular do alojamento, seja para a morada postal, seja para o endereço eletrónico que o referido titular deve fornecer, sem prejuízo da utilização de qualquer outro meio válido em direito que permita cumprir os requisitos legalmente válidos.`,
    p24: `Para comprovar a sua identidade no caso de desejar exercer os direitos acima mencionados, necessitaremos que nos forneça:`,
    p25: `-Nome e apelidos do interessado; cópia do BI, passaporte ou similar e, caso exista, da pessoa que o represente.`,
    p26: `-Objeto do pedido.`,
    p27: `-Morada para efeitos de notificação, data e assinatura do requerente.`,
    p28: `-Documentos que sustentem o pedido formulado caso se considerem convenientes ou necessários.`,

}
