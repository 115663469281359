<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="logoImage d-flex flex-row">
            <div>
              <img src="/logoFooter.png" alt="" class="img-fluid" />
            </div>
            <div class="informacionEmpresa d-flex flex-column">
              <div>QRCHECKIN LLC</div>
              <div>407 Lincoln Rd Ste 6h #1296,</div>
              <div>33139 Miami Beach, FL</div>
              <div>United States</div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="segundoBloque d-flex flex-column">
            <div>
              <div class="titleStrong">Funcionalidades</div>
            </div>
            <div class="informacionEmpresa d-flex flex-column">
              <div @click.prevent="CambiarRuta('Qrcheckin')">Checkin QR</div>
              <div @click.prevent="CambiarRuta('checkinOnlineRoute')">Checkin online</div>
              <div @click.prevent="CambiarRuta('whatsappPage')">
                WhatsApp de bienvenida
              </div>
              <div @click.prevent="CambiarRuta('Upselling')">Venta cruzada</div>
              <div @click.prevent="CambiarRuta('policePage')">Envío a autoridades</div>
              <div @click.prevent="CambiarRuta('MensajesAutomaticos')">
                Valoraciones automáticas Google review
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="segundoBloque d-flex flex-column">
            <div>
              <div class="titleStrong">Links de intereses</div>
            </div>
            <div class="informacionEmpresa d-flex flex-column">
              <router-link
                to="terminos"
                tag="li"
                class="d-flex flex-row mg0"
                style="cursor: pointer; color: #000"
              >
                <div>Términos y condiciones</div>
              </router-link>
              <router-link
                :to="{ name: 'pHospedaje' }"
                tag="li"
                class="d-flex flex-row mg0"
                style="cursor: pointer; color: #000"
              >
                <div>Políticas de privacidad(Huésped)</div>
              </router-link>
              <router-link
                :to="{ name: 'pHuesped' }"
                tag="li"
                class="max2 d-flex flex-row"
                style="cursor: pointer; color: #000"
              >
                <div>Políticas de privacidad(Alojamiento)</div>
              </router-link>
            </div>

            <div>
              <div class="titleStrong mt-2">Contacto</div>
            </div>
            <div class="informacionEmpresa d-flex flex-column">
              <div>Contacta con ventas</div>
              <div>
                <a
                  style="text-decoration: none; color: #000"
                  href="mailto:soporte@qrcheckin.es"
                  target="_blank"
                  >Contacta con soporte</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <span>© 2024 Todos los derechos reservados</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    CambiarRuta(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
        window.scrollTo(0, 0); // Esto llevará la página al principio
      }
    },
  },
};
</script>
<style lang="scss" scope>
.logoImage {
  align-items: flex-end;
  img {
    margin-right: 20px;
    width: 80px;
  }
  .informacionEmpresa {
    font-size: 15px;
    font-weight: 500;
  }
}

.segundoBloque {
  .informacionEmpresa {
    div {
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
.titleStrong {
  color: #000;
  font-weight: bold;
  font-size: 19px;
}
.row {
  text-align: left;
}
</style>
