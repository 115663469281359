export const terminos = {
p1:`Termos de Uso`,
p2:`INFORMAÇÃO GERAL`,
p3:`Esta página, o seu conteúdo e infraestrutura, e o serviço de gestão de reservas de alojamento que fornecemos nesta página e através deste site pertencem, são geridos e administrados pela QRCHECKIN LLC (doravante QRCheckin). Em todo o site, bem como na App QRCheckin e em qualquer dos produtos oferecidos pela empresa (doravante, ao referir-nos ao site ou ao site da QRCheckin, estaremos a referir-nos a todas as plataformas onde a QRCheckin fornece os seus serviços e todas as informações nelas contidas), os termos "nós", "nos" e "nosso" referem-se à QRCheckin. A QRCheckin disponibiliza este site, e o utilizador está condicionado à aceitação de todos os termos, condições, políticas e notificações aqui estabelecidos.`,
p4:`Ao visitar o nosso site, e/ou comprar algum dos nossos produtos, está a participar no nosso "Serviço" e aceita os seguintes termos e condições ("Termos de Serviço", "Termos"), incluindo todos os termos e condições adicionais e as políticas referenciadas nestes Termos, bem como as políticas e condições disponíveis através de hiperligações. Estes Termos de Serviço aplicam-se a todos os utilizadores da QRCheckin, incluindo, sem limitação, utilizadores que sejam navegadores, fornecedores, clientes, e/ou colaboradores, sem que esta lista sirva de limitação alguma.`,
p5:`Por favor, leia estes Termos de Serviço cuidadosamente antes de aceder ou utilizar o nosso site. Ao aceder ou utilizar qualquer parte do site, concorda em ficar vinculado a estes Termos de Serviço. Se não concordar com todos os termos e condições, não deve aceder ao site nem utilizar quaisquer dos nossos serviços.`,


p6: `Qualquer nova função ou ferramenta adicionada à nossa oferta de produtos e serviços também estará sujeita aos Termos de Serviço. Você pode revisar a versão atualizada dos Termos a qualquer momento nesta página. A QRCheckin reserva-se o direito de atualizar, alterar ou substituir qualquer parte dos Termos de Serviço ao publicar atualizações e/ou alterações em nosso site. É responsabilidade do usuário revisar esses Termos periodicamente para verificar se há alterações. Seu uso contínuo ou acesso ao site após a publicação de qualquer alteração constitui aceitação dessas alterações.`,
p7: `Estes termos foram redigidos de acordo com as disposições da legislação aplicável em matéria de Internet, comércio eletrónico e proteção de dados, entre outras.`,
p8: `SEÇÃO 1 - CONDIÇÕES GERAIS`,
p9: `Você não pode usar os produtos da QRCheckin para qualquer propósito ilegal ou não autorizado, nem pode, no uso do Serviço, violar qualquer lei aplicável a você. Não deve transmitir worms, vírus ou qualquer código de natureza destrutiva.`,
p10: `Além das proibições mencionadas, é proibido o uso do site ou seu conteúdo: (a) para pedir a outros que realizem ou participem de atos ilícitos; (b) para infringir ou violar os direitos de propriedade intelectual da QRCheckin ou de terceiros; (c) para assediar, abusar, insultar, prejudicar, difamar, caluniar, denegrir, intimidar ou discriminar por razões de gênero, orientação sexual, religião, etnia, raça, idade, nacionalidade ou deficiência; (d) para apresentar informações falsas ou enganosas; (e) para coletar ou rastrear informações pessoais de outros; (f) para gerar spam, phish, pharm, pretexto, spider, crawl ou scrape; (g) para qualquer propósito obsceno ou imoral; ou (h) para interferir ou contornar os elementos de segurança do Serviço.`,
p11: `Reservamo-nos o direito de suspender o uso do Serviço ou de qualquer site relacionado por violação de qualquer um dos itens das utilizações proibidas.`,
p12: `O não cumprimento ou violação de qualquer um destes Termos resultará no término imediato dos Serviços e no exercício das ações legais e de reparação que sejam necessárias.`,
p13: `A QRCheckin reserva-se o direito de recusar a prestação de Serviços a qualquer pessoa, por qualquer motivo e a qualquer momento.`,
p14: `Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Serviço, uso do Serviço ou acesso ao Serviço ou qualquer contato no site através do qual o serviço é prestado sem permissão por escrito da QRCheckin.`,
p15: `Os títulos e cabeçalhos utilizados na redação são incluídos apenas para conveniência e não limitam ou afetam estes Termos.`,
p16: `SEÇÃO 2 - EXATIDÃO, INTEGRALIDADE E ATUALIDADE DA INFORMAÇÃO`,
p17: `A QRCheckin não é responsável se a informação disponível neste site não for precisa, completa ou atual. O material neste site é fornecido apenas para fins de informação geral e não deve ser confiado ou utilizado como única base para a tomada de decisões sem consultar informações mais precisas, completas ou oportunas anteriormente.`,
p18: `Este site pode conter certas informações históricas ou passadas. Essas informações não são necessariamente atuais e são fornecidas apenas como referência. A QRCheckin reserva-se o direito de modificar o conteúdo deste site a qualquer momento, mas não é obrigada a atualizar as informações. Você concorda que é sua responsabilidade revisar e verificar as alterações em nosso site.`,


p19: `SEÇÃO 3 - MODIFICAÇÕES NO SERVIÇO E PREÇOS`,
p20: `Os preços de nossos produtos estão sujeitos a possíveis alterações sem a necessidade de aviso prévio. Da mesma forma, a QRCheckin reserva-se o direito de modificar ou suspender o Serviço (ou qualquer parte dele) a qualquer momento, sem aviso prévio.`,
p21: `A QRCheckin não é responsável perante você ou qualquer terceiro por qualquer modificação, alteração de preço, suspensão ou interrupção do Serviço.`,
p22: `SEÇÃO 4 - PRODUTOS E SERVIÇOS`,
p23: `Determinados produtos ou serviços podem estar disponíveis exclusivamente a critério da QRCheckin. Esses produtos ou serviços podem estar sujeitos a devolução ou troca de acordo com nossa "Política de Devolução".`,
p24: `Todas as descrições de produtos ou preços de produtos estão sujeitas a alterações a qualquer momento e sem aviso prévio, a critério exclusivo da QRCheckin. A QRCheckin não é responsável por expectativas sobre seus produtos e serviços geradas neste site ou através de nossas campanhas de comunicação comercial, e não garante que os erros no Serviço sejam corrigidos. A QRCheckin reserva-se o direito de suspender qualquer produto a qualquer momento. Qualquer oferta de produto ou serviço feita neste site é nula onde possa infringir as leis.`,
p25: `Pode haver informações no site da QRCheckin ou no conteúdo do Serviço que contenham erros tipográficos, imprecisões ou omissões relacionadas a descrições de produtos, preços, promoções, ofertas, tempo de trânsito e disponibilidade. A QRCheckin reserva-se o direito de corrigir tais erros, imprecisões ou omissões a qualquer momento, sem aviso prévio.`,


p26: `SEÇÃO 5 - POLÍTICA DE DEVOLUÇÃO`,
p27: `A QRCheckin é uma empresa que oferece seus serviços sob um modelo de assinatura, o cliente paga uma taxa periodicamente (mensal ou anual) em troca de serviços prestados após o recebimento do pagamento. Uma vez que o cliente insere os dados de pagamento (número do cartão, data de validade e qualquer outra informação necessária para processar a transação), ele dá seu consentimento à QRCheckin para realizar as cobranças pertinentes através desse método de pagamento.`,
p28: `É responsabilidade do cliente monitorar e revisar seu período de faturamento e a próxima cobrança a ser feita, de modo que, se não desejar continuar com o serviço, é responsabilidade do cliente cancelar a assinatura. Caso contrário, a próxima cobrança será realizada e nenhum direito a reembolso será gerado, uma vez que, após o pagamento, os serviços da QRCheckin estarão disponíveis para uso pelo cliente durante o restante do período de assinatura.`,
p29: `No entanto, a QRCheckin reserva-se o direito de reembolsar estudando o caso específico e sempre atendendo a razões objetivas e justas, como uma interrupção do serviço que impossibilite o cancelamento da assinatura ou um caso de fraude com cartão bancário.`,
p30: `SEÇÃO 6 - PRECISÃO DE FATURAMENTO E INFORMAÇÕES DA CONTA`,
p31: `Você concorda em fornecer informações atuais, completas e precisas sobre os detalhes de sua conta, informações de faturamento, dados de contato e outras informações necessárias para o bom funcionamento dos Serviços. Você concorda em atualizar suas informações de pagamento (números de cartão, data de validade, etc.) para que possamos concluir suas transações.`,
p32: `SEÇÃO 7 - FERRAMENTAS OPCIONAIS E LINKS DE TERCEIROS`,
p33: `Podemos fornecer a você acesso a ferramentas/plataformas de terceiros sobre as quais não temos controle nem entrada. Você reconhece e concorda que fornecemos acesso a esse tipo de ferramentas "como estão" e "conforme disponibilidade", sem garantias, representações ou condições de qualquer tipo e sem nenhum respaldo. Não teremos responsabilidade decorrente do uso de ferramentas fornecidas por terceiros. Qualquer uso que você faça dessas ferramentas opcionais oferecidas através do site será por sua própria conta e risco, e você deverá garantir que está familiarizado e concorda com os termos sob os quais essas ferramentas são fornecidas.`,
p34: `Certos conteúdos, produtos e/ou serviços oferecidos pela QRCheckin podem incluir materiais de terceiros. Existem links de terceiros neste site que podem redirecioná-lo para sites que não estão sob nosso controle. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou a precisão e não garantimos nem teremos qualquer obrigação ou responsabilidade por qualquer material de terceiros.`,


p35: `SEÇÃO 8 - INFORMAÇÃO PESSOAL`,
p36: `O tratamento de informações pessoais pela QRCheckin é regido pela nossa Política de Privacidade. Os dados confiados à QRCheckin serão tratados de forma confidencial e incorporados aos respectivos arquivos dos quais a QRCHECKIN LLC, com sede em 407 Lincoln Rd Ste 6h #1296, 33139 Miami Beach, FL United States, é responsável, uma vez que o usuário tenha dado seu consentimento explícito.`,
p37: `Com tal consentimento, a QRCheckin obtém permissão para coletar os dados dos hóspedes com a única finalidade de registrá-los nas plataformas destinadas para esse fim pelos Corpos e Forças de Segurança do Estado, tornando-se o encarregado desse tratamento, sendo o usuário titular do alojamento o responsável pelo tratamento dos dados. Para formalizar este encargo de tratamento, a QRCheckin e o usuário devem assinar um contrato de encargo de tratamento. Caso contrário, o usuário pode, mediante a aceitação destes Termos, aderir ao encargo de tratamento genérico.`,
p38: `Em relação ao parágrafo anterior, a QRCheckin informa aos usuários titulares dos alojamentos que devem oferecer aos hóspedes uma política de privacidade indicando quais dados serão tratados e com que finalidade, estabelecendo que a QRCheckin é a encarregada do tratamento e eles próprios (titulares do alojamento) são os responsáveis.`,
p39: `Se os usuários da QRCheckin não oferecerem uma política de privacidade aos seus hóspedes, o tratamento dos dados poderá ser regido pela seguinte política de privacidade genérica.`,
p40: `O tratamento dos dados pessoais dos usuários realizado pela QRCheckin é realizado conforme previsto na Lei Orgânica 3/2018, de 5 de dezembro, de Proteção de Dados Pessoais e garantia dos direitos digitais, em desenvolvimento do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, relativo à proteção das pessoas físicas no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados e revogando a Diretiva 95/46/CE, bem como qualquer outra disposição destinada ao desenvolvimento desse corpo normativo.`,
p41: `SEÇÃO 9 - LIMITAÇÃO DE RESPONSABILIDADE`,
p42: `Sujeito às limitações estabelecidas nestes Termos e na medida permitida por lei, só seremos responsáveis pelos danos diretos realmente sofridos, pagos ou incorridos por você devido a uma deficiência atribuível às obrigações da QRCheckin com relação aos serviços que a empresa oferece.`,
p43: `No entanto, e na medida permitida por lei, nem a QRCheckin nem qualquer um de seus diretores, funcionários, representantes, empresas afiliadas, distribuidores, licenciados, agentes ou outros envolvidos na criação, patrocínio, promoção da QRCheckin e seu conteúdo serão responsáveis por (a) interrupções, erros ou suspensões inoportunas, (b) a precisão e confiabilidade dos dados, (c) qualquer dano direto, indireto, incidental ou consequencial de qualquer tipo, incluindo, entre outros, perda de receita, perda de dados, custos de substituição ou qualquer dano semelhante, seja por delito (incluindo negligência), responsabilidade direta ou de outra forma, decorrente do uso de qualquer um dos serviços ou produtos adquiridos através do Serviço, ou para qualquer outra reivindicação relacionada de alguma forma com o uso do Serviço ou qualquer produto, incluindo, entre outros, qualquer erro ou omissão em qualquer conteúdo, ou qualquer perda ou dano de qualquer tipo incorrido como resultado do uso do Serviço ou qualquer conteúdo (ou produto) publicado, transmitido ou disponibilizado através do Serviço, mesmo que tenha sido informado da possibilidade desses danos. Como alguns estados ou jurisdições não permitem a exclusão ou limitação de responsabilidade por danos consequenciais ou incidentais, em tais estados ou jurisdições, a responsabilidade da QRCheckin será limitada ao máximo permitido por lei.`,
p44: `No uso da Plataforma, é responsabilidade do usuário (a) verificar as informações sobre a identificação do hóspede por qualquer meio que considere adequado e em conformidade com a legislação vigente, (b) verificar o correto envio de informações, dados dos hóspedes e partes dos viajantes, bem como quaisquer outros requisitos que a legislação vigente exija, à autoridade ou administração correspondente, (c) cumprir as condições necessárias para garantir a leitura precisa da digitalização do ID do hóspede, (d) e cumprir os possíveis requisitos adicionais no registro de hóspedes que a legislação possa estabelecer e que não estejam incluídos nos serviços prestados pela QRCheckin.`,


p45: `SEÇÃO 10 - INDENIZAÇÃO`,
p46: `Você concorda em indenizar, defender e/ou isentar de responsabilidade a QRCheckin, suas afiliadas, associadas, parceiros, funcionários, diretores, agentes, contratantes, concessionários, fornecedores de serviços e subcontratados, de qualquer reclamação ou demanda, incluindo honorários advocatícios razoáveis, feitos por qualquer terceiro devido ou resultante do seu descumprimento dos Termos ou das condições e políticas incorporadas a estes por referência, ou da violação de qualquer lei ou dos direitos de terceiros.`,
p47: `SEÇÃO 11 - DIVISIBILIDADE`,
p48: `No caso de determinação de que qualquer disposição destes Termos seja ilegal, nula ou inexequível, tal disposição será considerada separada destes Termos, e tal determinação não afetará a validade das demais disposições.`,
p49: `SEÇÃO 12 - LEI`,
p50: `Estes Termos e qualquer outra condição, política ou acordo nos quais a QRCheckin forneça seus serviços serão regidos e interpretados de acordo com as leis espanholas e, seguindo a ordem de precedência das normas estabelecidas no direito espanhol, atendendo às normativas autonômicas de Madrid e às regulamentações locais da cidade de Madrid.`,
p51: `SEÇÃO 13 - CONTATO`,
p52: `Qualquer questão sobre os Termos de Serviço deve ser enviada para support@qrcheckin.es.`,
p53: `SEÇÃO 14 - IDIOMA`,
p54: `O idioma aplicável a estes Termos é o espanhol. Qualquer versão destes em idioma diferente é oferecida para conveniência do Usuário e para facilitar sua compreensão. No entanto, estes Termos serão sempre regidos por sua versão em espanhol.`,
p55: `Em caso de contradição entre os Termos em qualquer idioma e sua versão em espanhol, prevalecerá a versão em espanhol.`,


p56: `Política de Privacidade`,
p57: `Responsável pelo tratamento`,
p58: `O responsável pelo tratamento dos dados coletados é a QRCHECKIN LLC, com sede em 407 Lincoln Rd Ste 6h #1296, 33139 Miami Beach, FL United States, titular do App QRCheckin e da plataforma web QRCheckin.`,
p59: `Esta Política de Privacidade regula a coleta e o tratamento dos dados pessoais fornecidos pelos titulares dos alojamentos ao acessarem e utilizarem as funcionalidades do App QRCheckin e da plataforma web QRCheckin.`,
p60: `Todos os dados serão tratados de acordo com as disposições do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, relativo à proteção das pessoas físicas no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados e que revoga a Diretiva 95/46/CE (Regulamento Geral de Proteção de Dados).`,
p61: `Coleta de dados, finalidades, base de legitimidade e prazos de conservação:`,
p62: `a. Cadastro no App/plataforma web e registro do titular do alojamento:`,
p63: `Por meio deste registro, será necessário que o usuário titular do alojamento forneça seus dados de identificação, como nome, e-mail e senha. Os dados serão tratados para cadastrá-lo no aplicativo e na plataforma web e permitir o acesso aos serviços oferecidos.`,
p64: `A base jurídica do tratamento desses dados é o cumprimento do contrato ou licença de uso.`,
p65: `O prazo de conservação dos dados para esta finalidade será mantido enquanto o titular estiver cadastrado e não tiver solicitado a exclusão do aplicativo.`,
p66: `b. Assinatura:`,
p67: `O titular do alojamento pode assinar os serviços oferecidos pelo QRCheckin. Para isso, ele deve fornecer os dados do seu cartão de crédito/débito. Os dados serão tratados para processar o pagamento dos serviços.`,
p68: `A base jurídica do tratamento desses dados é o cumprimento do contrato ou licença de uso.`,
p69: `Os dados serão mantidos pelo período necessário para cumprir obrigações legais ou para lidar com reclamações ou responsabilidades decorrentes do tratamento.`,
p70: `c. Cookies:`,
p71: `O App e o site utilizam tecnologia para implantar arquivos chamados cookies no dispositivo usado para acessar e navegar no App e no site. Os cookies podem ser bloqueados ou desativados a qualquer momento por meio das configurações do navegador.`,
p72: `A base jurídica do tratamento desses dados é o consentimento do usuário.`,
p73: `d. Publicidade:`,
p74: `O QRCheckin poderá tratar os dados dos usuários titulares dos alojamentos para mantê-los atualizados sobre sua atividade e novidades relacionadas aos seus serviços com base em seu interesse legítimo por meios eletrônicos. O titular pode se opor a isso no momento de seu cadastro no App/plataforma web ou exercer seu direito de oposição.`,
p75: `O prazo de conservação dos dados será o período em que permanecer cadastrado em nosso sistema para o envio de publicidade e não tiver revogado seu consentimento.`,


p76: `Exercício de direitos`,
p77: `Além disso, o Usuário pode revogar o consentimento para o tratamento, exercendo os direitos de acesso, retificação, exclusão, portabilidade, oposição e limitação ao tratamento, notificando a QRCheckin por e-mail para support@qrcheckin.es, com o assunto "Proteção de Dados". Em circunstâncias especiais, poderemos solicitar ao Usuário que comprove sua identidade por meio de documento oficial, por exemplo, para evitar o acesso de terceiros aos seus dados em caso de suspeita de fraude.`,
p78: `Por fim, o Usuário pode comunicar qualquer modificação por escrito ou solicitar a exclusão, indicando isso em qualquer um dos endereços fornecidos.`,
p79: `A qualquer momento, o Usuário pode apresentar uma reclamação à Agência Espanhola de Proteção de Dados para exercer seus direitos.`,
p80: `Modificação da Política de Privacidade`,
p81: `A QRCheckin reserva o direito de modificar sua política de privacidade a qualquer momento, respeitando a legislação vigente em matéria de proteção de dados e após a devida comunicação aos interessados.`,
p82: `Idioma aplicável a esta política de privacidade`,
p83: `O idioma aplicável a esta Política de Privacidade é o espanhol. Qualquer versão em idioma diferente é oferecida para conveniência do Usuário e para facilitar sua compreensão. No entanto, esta Política de Privacidade será sempre regida por sua versão em espanhol.`,
p84: `Em caso de contradição entre a Política de Privacidade em qualquer idioma e sua versão em espanhol, prevalecerá a versão em espanhol.`,
p85: `Política de Privacidade Genérica dos Alojamentos`,
p86: `O quadro normativo geral é o GDPR europeu 679/2016 sobre proteção de dados pessoais.`,
p87: `1. Geral`,
p88: `Para habilitar o uso do aplicativo QRCheckin e das demais plataformas onde os serviços do QRCheckin são oferecidos, é necessário ser usuário registrado e aceitar as regras de privacidade detalhadas abaixo para esse fim, de acordo com as estabelecidas no Regulamento Europeu de Proteção de Dados (Regulamento 2016/679) e as leis específicas do país e região onde se encontra o sujeito dos dados a serem tratados.`,
p89: `Ao utilizar os serviços do QRCheckin e, consequentemente, aceitar sua política de privacidade, você concorda que o proprietário do alojamento, responsável por seus dados pessoais, armazene e processe os dados pessoais que você nos fornece para usar a plataforma. Em todos os casos, o hóspede é informado de que os seguintes tratamentos são realizados com base nas informações fornecidas.`,
p90: `2. O que fazemos com seus dados?`,
p91: `Os tratamentos e, portanto, o uso de dados pessoais que são realizados são os seguintes:`,
p92: `- Gestão de estadias, para controlar a estadia nos alojamentos.`,
p93: `- Informação às Autoridades, conforme exigido pela regulamentação turística, pois pode haver uma obrigação legal de fazê-lo.`,
p94: `3. Quais dados pessoais tratamos no aplicativo?`,
p95: `Os seguintes dados podem ser processados (em algumas regiões, a coleta de alguns dados não é obrigatória) para administrar sua estadia e enviar informações às Autoridades: Nome, Sobrenome, Segundo Sobrenome, Sexo, Nacionalidade, Data de Nascimento, País de Nascimento, Cidade de Nascimento, País de Residência, Cidade de Residência, Endereço de Residência, Tipo de Identificação, Número de Identificação, Data de Emissão da Identificação, País de Emissão, Cidade de Emissão, Propósito da Estadia, Número de Visto, Assinatura.`,



p96: `4. Quanto tempo os dados são armazenados?`,
p97: `Em geral, armazenamos as informações pelo tempo necessário para realizar os diferentes tratamentos e, pelo menos, de acordo com a regulamentação turística, por um período de 3 anos.`,
p98: `5. Para quem transferimos os dados?`,
p99: `Quaisquer dados coletados pelo proprietário do alojamento, em nenhum caso, serão transferidos para terceiras empresas, exceto nos casos detalhados a seguir:`,
p100: `- Todos os tratamentos mencionados anteriormente são exclusivamente transferidos para a QRCheckin para os fins de gestão completa do registro de hóspedes e outros serviços acordados entre a QRCheckin e o proprietário do alojamento.`,
p101: `- A transferência de tratamentos para as Autoridades.`,
p102: `6. Tomamos decisões automatizadas?`,
p103: `Não, atualmente não estão sendo tomadas decisões automatizadas de tratamento.`,
p104: `7. Direitos de acesso, retificação, exclusão, limitação do tratamento, portabilidade de dados e oposição.`,
p105: `De acordo com o Regulamento Geral de Proteção de Dados, juntamente com o direito de se opor à automatização de decisões, qualquer usuário que tenha fornecido dados ao proprietário do alojamento tem os seguintes direitos que podem ser exercidos:`,
p106: `Acesso.`,
p107: `Retificação.`,
p108: `Exclusão.`,
p109: `Limitação de tratamento.`,
p110: `Portabilidade dos dados.`,
p111: `Oposição.`,
p112: `O exercício desses direitos deve ser feito por meio de comunicação dirigida ao proprietário do alojamento, seja para o endereço postal, seja para o endereço eletrônico que o referido proprietário deve fornecer, sem prejuízo do uso de qualquer outro meio legalmente válido que permita cumprir os requisitos legais.`,
p113: `Para comprovar sua identidade caso deseje exercer os direitos mencionados acima, precisaremos que você nos forneça:`,
p114: `- Nome e sobrenome do interessado; cópia do RG, passaporte ou documento similar e, se aplicável, da pessoa que o represente.`,
p115: `- Objeto da solicitação.`,
p116: `- Endereço para notificação, data e assinatura do solicitante.`,
p117: `- Documentos que fundamentem o pedido formulado, caso sejam considerados adequados ou necessários.`,
p118: ``,
p119: ``,
p120: ``,


}