export const mensajeautomatico = {
    p1: `Mensajes Automáticos..`,
    p2: `Haz que tu Hotel Sea Inolvidable con Mensajes Personalizados y Programados,`,
    p3: `Optimiza tu Check-in Ahora`,
    p4: `"Mensajes Personalizados, Llegadas Inolvidables: La Magia del Autocheck-in con QRCHECKIN`,
    p5: `Descubre cómo puedes ofrecer a tus visitantes una experiencia sin complicaciones`,
    p6: `Autocheckin Vía WhatsApp`,
    p7: `Mensajes Automáticos Programables`,
    p8: `Información sobre Servicios del Hotel`,
    p9: `Personalización a Medida`,
    p10: `Recordatorios automaticos vía WhatsApp`,
    p11: `Comenzar`,
    p12: `Adapta cada mensaje a la identidad única de tu hotel`,
    p13: `Desde la bienvenida hasta los detalles específicos`,
    p14:`Facilitamos el proceso de llegada al permitir que tus huéspedes realicen el check-in 
    con un simple enlace vía WhatsApp. Elimina colas y ofrece un ingreso rápido y cómodo.`,
    p15:``,
}