export const terminos = {
    p1:`Terms of Use`,
    p2:`GENERAL INFORMATION`,
    p3:`This page, its content and infrastructure, and the lodging reservation management service we provide on this page and through this
    website belong to, are managed and administered by QRCHECKIN LLC (hereinafter QRCheckin). Throughout the website, as well as the QRCheckin App and
    in any of the products offered by the company (hereinafter when referring
    to the website or QRCheckin's website, we will be referring to all
    platforms on which QRCheckin provides its services and all the
    information contained therein), the terms "we", "us" and "our" refer to QRCheckin. QRCheckin offers this website, and the user is
    conditioned to acceptance of all the terms, conditions, policies and
    notifications established herein.`,
    p4:`By visiting our website, and/or purchasing any of our products, you
    are engaging in our "Service" and agree to be bound by the following terms and conditions
    ("Terms of Service", "Terms"), including all additional terms and
    conditions and policies referenced herein,
    as well as those policies and conditions available through
    hyperlinks. These terms of service apply to all users
    of QRCheckin, including users who are browsers, suppliers,
    customers, and/or collaborators, without limitation.`,
    p5:`Please read these Terms of Service carefully before accessing or
    using our website. By accessing or using any part of the site,
    you agree to the Terms of Service. If you do not agree to all
    the terms and conditions, you should not access the website or use
    any of our services.`,
    p6:`Any new feature or tool added to our product
    and service offerings will also be subject to the Terms of Service. You may
    review the current version of the Terms at any time on
    this page. QRCheckin reserves the right to update, change or
    replace any part of the Terms of Service by posting
    updates and/or changes on our website. It is the responsibility of
    the user to review such Terms periodically to check for changes.
    Your continued use or access to the website after the posting of
    any changes constitutes acceptance of those changes.`,
    p7:`These terms have been drafted in accordance with the provisions of the
    current regulations applicable to the Internet, electronic commerce and
    data protection, among others.`,
    p8:`SECTION 1 - GENERAL CONDITIONS`,
    p9:`You may not use QRCheckin products for any illegal or unauthorized purpose,
    nor may you, in the use of the Service, violate any law applicable to you. You must not transmit worms, viruses, or any code of
    destructive nature.`,
    p10:`In addition to the prohibitions mentioned, the use of the website or
    its content is prohibited: (a) to solicit others to perform or participate in
    unlawful acts; (b) to infringe or violate QRCheckin's or any third party's intellectual property rights; (c) to harass, abuse, insult, harm,
    defame, slander, disparage, intimidate, or discriminate on grounds of
    gender, sexual orientation, religion, ethnicity, race, age, nationality, or
    disability; (d) to provide false or misleading information; (e) to
    collect or track personal information of others; (f) to generate spam,
    phish, pharm, pretext, spider, crawl, or scrape; (g) for any purpose
    obscene or immoral; or (h) to interfere with or circumvent the security features
    of the Service.`,
    p11:`We reserve the right to suspend the use of the Service or any
    related website for violating any of the prohibited uses.`,
    p12:`Failure to comply with or breach of any of these Terms shall result in
    immediate termination of Services and the pursuit of legal and
    compensatory actions as necessary.`,
    p13:`QRCheckin reserves the right to refuse provision of Services to
    any person, for any reason and at any time.`,
    p14:`You agree not to reproduce, duplicate, copy, sell, resell or
    exploit any part of the Service, use of the Service, or access to the Service
    or any contact on the website through which the service is provided
    without written permission from QRCheckin.`,
    p15:`The titles and headings used in the drafting are included for
    convenience only and do not limit or affect these Terms.`,
    p16:` SECTION 2 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION`,
    p17:`QRCheckin is not responsible if the information available on this site
    is not accurate, complete or current. The material on this site is provided
    for general information only and should not be relied upon or used
    as the sole basis for decision-making without consulting more accurate, complete or timely information beforehand.`,
    p18:`This website may contain certain historical or past information. Such
    information is not necessarily current and is provided solely as
    reference. QRCheckin reserves the right to modify the contents of this
    website at any time, but is not obligated to update the information.
    You agree that it is your responsibility to review and check for changes
    on our website.`,
    p19:`SECTION 3 - MODIFICATIONS TO THE SERVICE AND PRICES`,
    p20:`The prices of our products are subject to possible variations without
    prior notice. Likewise, QRCheckin reserves the
    right to modify or suspend the Service (or any part of it) at
    any time without prior notice.`,
    p21:`QRCheckin is not liable to you or any third party for any
    modification, price change, suspension or discontinuation of the Service.`,
    p22:`SECTION 4 - PRODUCTS AND SERVICES`,
    p23:`Certain products or services may be available exclusively at
    the discretion of QRCheckin. These products or services may be subject to
    return or exchange according to our "Return Policy".`,
    p24:`All product descriptions or product prices are subject to change at any time
    and without prior notice, at the sole discretion of
    QRCheckin. QRCheckin is not responsible for the expectations regarding its
    products and services generated on this website or through our
    commercial communication campaigns and does not guarantee that errors in the
    Service will be corrected. QRCheckin reserves the right to suspend
    any product at any time. Any offer of a product or
    service made on this website is void where prohibited by law.`,
    p25:`There may be information on the QRCheckin website or in the content
    of the Service that contains typographical errors, inaccuracies or omissions that
    may be related to product descriptions, prices,
    promotions, offers, transit times and availability. QRCheckin reserves the right to correct such errors, inaccuracies or omissions at
    any time without prior notice.`,

    p26:`SECTION 5 - RETURN POLICY`,
    p27:`QRCheckin is a company that offers its services under a subscription model, where the customer pays a fee periodically (monthly or annually) in
    exchange for services provided once payment is received. Once the customer
    enters payment details (credit card number, expiration date, and any other information necessary to process the transaction), they consent to QRCheckin to make the relevant charges through
    that payment method.`,
    p28:`It is the customer's responsibility to monitor and review their billing period and
    the next charge to be made, so if they do not wish to continue with
    the service, it is the customer who must cancel the service, otherwise
    the next charge will be processed, and no right to
    refund will be generated, since once payment is made, QRCheckin's
    services will be available for use by the customer during the remaining
    subscription time.`,
    p29:`However, QRCheckin reserves the right to refund by studying the specific case and always considering objective and fair reasons, such as a service outage that prevents cancellation of the subscription or credit card fraud.`,
    p30:`SECTION 6 - BILLING INFORMATION ACCURACY AND ACCOUNT INFORMATION`,
    p31:`You agree to provide current, complete, and accurate information
    regarding your account details, billing information, contact details, and any other information necessary for the proper functioning of the Services. You agree to update your payment information (credit card numbers, expiration dates, etc.), so that we can complete your transactions.`,
    p32:`SECTION 7 - OPTIONAL TOOLS AND THIRD-PARTY LINKS`,
    p33:`We may provide you with access to third-party tools/platforms over which we have no control or input. You acknowledge and agree that we provide access to such tools "as is" and "as available" without warranties, representations, or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising out of or
    related to the use of tools provided by third parties.
    Any use you make of such optional tools offered through the website will be at your own risk and discretion, and you should ensure that you are familiar with and agree to the terms under which these tools are provided.`,
    p34:`Certain content, products, and/or services that QRCheckin offers may include
    material from third parties. There are third-party links on this website
    that may redirect you to websites that are not under our control. We
    are not responsible for examining or evaluating the content or accuracy and do not
    guarantee or have any obligation or responsibility for any third-party material.`,

    p35:`SECTION 8 - PERSONAL INFORMATION`,
    p36:`The processing of personal information by QRCheckin is governed by
    our Privacy Policy. Data entrusted to QRCheckin will be treated
    confidentially and will be incorporated into the respective files of
    which QRCHECKIN LLC, with registered address at 407 Lincoln Rd
    Ste 6h #1296, 33139 Miami Beach, FL United States, is responsible,
    once the user has given their express consent.`,
    p37:`By means of this consent, QRCheckin obtains permission to collect
    guest data solely for the purpose of registering them on
    platforms designated for this purpose by the State Security Forces
    and Bodies, becoming the processor of said data, and
    the accommodation user being responsible for data processing.
    To formalize this data processing agreement, QRCheckin and the user
    must sign a data processing agreement. Alternatively, the
    user may, by accepting these Terms, opt for the generic data
    processing agreement.`,
    p38:`In connection with the preceding paragraph, QRCheckin informs accommodation owners that they must provide guests with a privacy policy indicating which data will be processed and for what
    purpose, and stating that QRCheckin is the processor and they themselves (the accommodation owners) are the controllers.`,
    p39:`If QRCheckin users do not provide a privacy policy to their
    guests, data processing may be governed by the following generic
    privacy policy.`,
    p40:`The processing of personal data of users carried out by QRCheckin
    is done in accordance with the provisions of Organic Law
    3/2018, of December 5, on Personal Data Protection and guarantee of digital rights, in implementation of Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC, as well as any other provision aimed at developing that regulatory framework.`,
    p41:`SECTION 9 - LIMITATION OF LIABILITY`,
    p42:`Subject to the limitations set forth in these Terms and to the extent
    permitted by law, we shall only be liable for direct damages
    actually suffered, paid, or incurred by you due to a deficiency
    attributable to QRCheckin's obligations regarding the services
    offered by the company.`,
    p43:`However, and to the extent permitted by law, neither QRCheckin nor any of
    its directors, employees, representatives, affiliated companies,
    distributors, licensors, agents, or other parties involved in the creation,
    sponsorship, promotion of QRCheckin and its content shall be liable for (a)
    interruptions, errors, or untimely suspensions, (b) the accuracy and
    reliability of the data, (c) any direct, indirect, incidental, or
    consequential damages of any kind, including, among others, loss of revenue,
    loss of data, replacement costs, or any similar damages, whether in
    tort (including negligence), strict liability, or otherwise, arising from the use of
    any of the services or products acquired through the Service, or for any other
    claim related in any way to the use of the Service or any product, including, among others,
    any errors or omissions in any content, or any loss or damage
    of any kind incurred as a result of the use of the Service or any
    content (or product) posted, transmitted, or made available through
    the Service, even if advised of such possibility. Because some
    states or jurisdictions do not allow the exclusion or limitation of
    liability for consequential or incidental damages, in such states or
    jurisdictions, the liability of QRCheckin shall be limited to the maximum
    extent permitted by law.`,
    p44:`In using the Platform, it is the user's responsibility to (a) verify
    the guest identification information by any means they consider appropriate and in accordance with current regulations, (b) verify the correct
    submission of guest information, traveler details, and any other
    requirements that current legislation may demand, to the relevant authority or
    administration, (c) comply with the necessary conditions
    to ensure accurate reading of the guest ID scan, (d) and
    comply with any additional requirements in guest registration
    that the legislation may establish and that are not included in the
    services provided by QRCheckin.`,

    p45:`SECTION 10 - INDEMNIFICATION`,
    p46:`You agree to indemnify, defend, and hold harmless QRCheckin, its subsidiaries, affiliates, partners, employees, directors, agents, contractors, licensors, service providers, and subcontractors from any claim or demand, including reasonable attorney's fees, made by any third party due to or arising out of your breach of the Terms or of the conditions and policies referenced herein, or your violation of any law or the rights of a third party.`,
    p47:`SECTION 11 - SEVERABILITY`,
    p48:`In the event that any provision of these Terms is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms; such determination shall not affect the validity and enforceability of any other remaining provisions.`,
    p49:`SECTION 12 - GOVERNING LAW`,
    p50:`These Terms and any other condition, policy, or agreement under which QRCheckin provides its services shall be governed and interpreted in accordance with Spanish laws and, following the order of precedence of rules established in Spanish law, taking into account the regional regulations of Madrid and the local regulations of the city of Madrid.`,
    p51:`SECTION 13 - CONTACT`,
    p52:`Any questions regarding the Terms of Service should be directed to soporte@qrcheckin.es.`,
    p53:`SECTION 14 - LANGUAGE`,
    p54:`The language applicable to these Terms is Spanish. Any version of the Terms in a different language is provided for the User's convenience and to facilitate understanding. However, these Terms shall always be governed by their Spanish version.`,
    p55:`In the event of any contradiction between the Terms in any language and their Spanish version, the Spanish version shall prevail.`,

    p56:`Privacy Policy`,
    p57:`Data Controller`,
    p58:`The data controller responsible for the collected data is QRCHECKIN LLC, with registered address at 407 Lincoln Rd Ste 6h #1296, 33139 Miami Beach, FL United States, owner of the QRCheckin App and the QRCheckin web platform.`,
    p59:`This Privacy Policy regulates the collection and processing of personal data provided by accommodation owners when accessing and using the functionalities of the QRCheckin App and the QRCheckin web platform.`,
    p60:`All data will be processed following the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).`,
    p61:`Data Collection, Purposes, Legal Basis, and Retention Periods:`,
    p62:`a. Registration on the App/web platform and accommodation owner's registration:`,
    p63:`Through this registration, it will be necessary for the accommodation owner to provide their identification data, such as name, email, and password. The data will be processed to register them in the application and web platform and allow them access to the services offered.`,
    p64:`The legal basis for the processing of such data is based on the fulfillment of the contract or license of use.`,
    p65:`The retention period of the data for this purpose will be for as long as the owner remains registered and has not exercised the application's cancellation.`,
    p66:`b. Subscription:`,
    p67:`The accommodation owner can subscribe to the services offered by QRCheckin. To do this, they must provide their credit/debit card details. The data will be processed to manage the payment of the services.`,
    p68:`The legal basis for the processing of such data is based on the fulfillment of the contract or license of use.`,
    p69:`The data will be retained for the period required to fulfill legal obligations or to handle claims or liabilities arising from the processing.`,
    p70:`c. Cookies:`,
    p71:`The App and the website have technology for the implementation of files called cookies on the device used to access and navigate the App and the website. Cookies can be blocked or disabled at any time through the browser's settings.`,
    p72:`The legal basis for the processing of such data is based on the User's consent.`,
    p73:`d. Advertising:`,
    p74:`QRCheckin may process the data of accommodation owners to keep them updated on its activity and news regarding information related to its services based on its legitimate interest through electronic means. The owner can oppose this at the time of registration on the App/web platform or exercise their right to object.`,
    p75:`The data retention period will be for as long as the owner remains registered in our system for sending advertising and has not revoked their consent.`,
    p76:`Exercise of Rights`,
    p77:`Likewise, the User may revoke consent for processing by exercising their rights of access, rectification, deletion, portability, objection, and restriction of processing, by notifying QRCheckin through an email addressed to soporte@qrcheckin.es indicating "Data Protection" as the subject. On some occasions and depending on the circumstances, we may ask the User to verify their identity through an official document, for example, to prevent access to their data by third parties when there may be suspicion of fraud.`,
    p78:`Finally, the User may communicate any modifications in writing or request cancellation, indicating this in any of the provided addresses.`,
    p79:`At any time, the User may file a complaint with the Spanish Data Protection Agency to enforce their rights.`,
    p80:`Modification of the Privacy Policy`,
    p81:`QRCheckin reserves the right to modify its privacy policy at any time, respecting current data protection legislation and after due communication to the interested parties.`,
    p82:`Language Applicable to this Privacy Policy`,
    p83:`The language applicable to this Privacy Policy is Spanish. Any version of it in a different language is provided for the User's convenience and to facilitate understanding. However, this Privacy Policy shall always be governed by its Spanish version.`,
    p84:`In case of contradiction between the Privacy Policy in any language and its Spanish version, the Spanish version shall prevail.`,


    p85:`Generic Accommodation Privacy Policy`,
    p86:`The general regulatory framework is the European GDPR 679/2016 on the protection of personal data.`,
    p87:`1. General`,
    p88:`To enable the use of the QRCheckin application and the rest of the platforms where QRCheckin services are offered, it is necessary to be a registered user and accept the privacy rules detailed below for this purpose, in accordance with those established in the European Data Protection Regulation (Regulation 2016/679) and the specific laws of the country and region where the data subject to be processed is located.`,
    p89:`By using QRCheckin services, and consequently accepting its privacy policy, you agree that the accommodation owner, responsible for your personal data, may store and process the personal data you provide to us for the purpose of using the platform. In any case, the guest is informed that the following processing is carried out based on the information provided.`,
    p90:`2. What do we do with your data?`,
    p91:`The processing, and therefore, the use of personal data carried out are as follows:`,
    p92:`- Stay management, in order to control the stay in the accommodations.`,
    p93:`- Information to Authorities, as required by tourism regulations, as there may be a legal obligation to do so.`,
    p94:`3. What personal data do we process in the application?`,
    p95:`The following data may be processed (there are some regions where the collection of certain data is not mandatory) to manage your stay and send information to the Authorities: Name, Surname, Second Surname, Gender, Nationality, Date of Birth, Country of Birth, city of birth, country of residence, city of residence, residence address, type of identification, identification number, identification date, identification country, issuing city, purpose of stay, visa number, signature.`,
    p96:`4. How long are they stored?`,
    p97:`In general, we store the information for the time necessary to carry out the different treatments, and at least, in accordance with tourism regulations, for a period of 3 years.`,
    p98:`5. To whom do we transfer the data?`,
    p99:`Any data collected by the accommodation owner will not be transferred to third-party companies, except in the cases detailed below:`,
    p100:`- All the aforementioned treatments are exclusively transferred to QRCheckin for the purpose of the complete management of guest registration and other services agreed between QRCheckin and the accommodation owner.`,
    p101:`- Transfer of treatments to the Authorities.`,
    p102:`6. Do we make automated decisions?`,
    p103:`No, currently no automated processing decisions are being made.`,
    p104:`7. Rights of access, rectification, deletion, limitation of processing, data portability, and objection.`,
    p105:`In accordance with the General Data Protection Regulation, along with your right to object to decision automation, any user who has provided data to the accommodation owner has the following rights that they can exercise:`,
    p106:`Access.`,
    p107:`Rectification.`,
    p108:`Deletion.`,
    p109:`Limitation of processing.`,
    p110:`Data portability.`,
    p111:`Objection.`,
    p112:`The exercise of these rights must be carried out through communication addressed to the accommodation owner, either to the postal address or to the email address that said owner must provide, without prejudice to the use of any other valid means in law that allows compliance with legally valid requirements.`,
    p113:`To verify your identity if you wish to exercise the aforementioned rights, we will need you to provide us with:`,
    p114:`- Full name of the interested party; copy of ID card, passport, or similar and, if applicable, of the person representing them.`,
    p115:`- Purpose of the request.`,
    p116:`- Address for notification purposes, date, and signature of the applicant.`,
    p117:`- Documents supporting the request made if they are deemed appropriate or necessary.`,
    p118:``,

}