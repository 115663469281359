export const checkin = {
    p1: `Online Check-in`,
    p2: `Once the reservation is confirmed, your guests will receive a link that will take them to the online check-in, where they will complete the registration process. Easy, fast, and simple.`,
    p3: `Sign Up for Free`,
    p4: `A 100% automated process,`,
    p5: `validated by nearly 1 million guests.`,
    p6: `A before and after in your registration process`,
    p7: `Significantly improve your guests' experience.`,
    p8: `Reduce your waiting time and that of your guests during registration.`,
    p9: `Offer a unique online check-in experience in the industry.`,
    p10: `Forget tedious and manual processes.`,
    p11: `All generated data will be stored in a secure cloud.`,
    p12: `Get Started`,

    p13: `Specially designed to meet all your expectations`,
    p14: `The online check-in process is very simple and can be completed in less than 1 minute.`,
    p15: `Scan QR Code`,
    p16: `Once your guests arrive at the hotel, they will find a QR code or receive a direct link to our online check-in web application.`,
    p17: `Scan Document`,
    p18: `At this stage, we will request that they scan their valid identification document, either a passport or national identity card.`,
    p19: `Sign Accommodation Policies`,
    p20: `Once the document is scanned, they will reach the final stage of the check-in process. Here, they can electronically sign using the same system.`,
    p21: `Terms and
    conditions`,
    p22: ``,
    p23: ``,
    p24: ``,
    p25: ``,

}