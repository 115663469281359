export const pagewhatsapp = {
    p1: `WhatsApp de Boas-vindas`,
    p2: `Ofereça uma mensagem de boas-vindas personalizada através do WhatsApp ao concluir
    o processo de check-in e faça com que seus hóspedes se sintam valorizados e
    especiais desde o primeiro momento.`,
    p3: `Começar`,
    p4: `Destaque-se da concorrência e aumente a satisfação e fidelidade dos seus
    clientes com o nosso serviço inovador!`,
    p5: `Vantagens de utilizar o WhatsApp no processo de check-in.`,
    p6: `Oferecer uma mensagem de boas-vindas personalizada através do WhatsApp cria
    uma experiência única e especial para cada hóspede.`,
    p7: `Ofereça um valor acrescentado aos seus hóspedes e destaque-se da concorrência,
    melhorando a satisfação dos seus clientes.`,
    p8: `Reduza o tempo e os recursos necessários para realizar o check-in,
    automatizando o processo e oferecendo uma experiência mais eficiente e
    rápida para os seus hóspedes.`,
    p9: `Ao oferecer um serviço inovador e conveniente, melhora-se a satisfação
    e fidelidade dos hóspedes, o que pode ter um impacto positivo na
    reputação e no sucesso das suas propriedades.`,
    p10: `Começar`,
    p11: `Revitalize o seu check-in`,
    p12: `Transforme a experiência dos seus hóspedes e aumente o sucesso do seu negócio.`,
    p13: `Os hóspedes receberão automaticamente uma mensagem de boas-vindas personalizada,
    Tu tens a oportunidade de adicionar um toque especial, personalizando a mensagem
    para cada um deles.`,
    p14: `Termos e
    condições`,
    p15: ``,
    p16: ``,
}
