export const precios = {
    p1: `Select subscription`,
    p2: `Find the perfect plan and start using QRCheckin today`,
    p3: `Subscription Type`,
    p4: `Tourist apartment`,
    p5: `month`,
    p6: `Properties`,
    p7: `Annual`,
    p8: `Monthly`,
    p9: `per property`,
    p10: `annual`,
    p11: `monthly`,
    p12: `Start`,
    p13: `total price -`,
    p14: `Hotel - Hostel`,
    p15: `Rooms`,
    p16: `per room`,
    p17: `Camping`,
    p18: `Villa`,
    p19: `Save up to 20%`,

    p20: `The "accommodation"`,
    p21: `is based on individual houses or apartments. For the`,
    p22: `"aparthotel"`,
    p23: `each apartment counts as a single accommodation`,

    p24: `Counts as`,
    p25: `Rooms`,
    p26: `in the case of hotels, as beds in the case of hostels, and as`,
    p27: `people`,
    p28: `in the case of camping.`,
        
    p29: `For example:`,
    p30: `A hostel with 4 rooms and 20 beds counts as
    20 accommodation units`,


    // Corrections  
        
    p31: `CUSTOMIZED`,
    p32: `month`,
    p33: `per`,
    p34: `room`,
    p35: `apartment`,
    p36: `Plan Details`,
    p37: `On-site check-in via QR`,
    p38: `Check-in via link before sending`,
    p39: `Welcome email message`,

    p40: `Biometric policy signature`,
    p41: `ID - Passport scanner`,
    p42: `Unlimited cloud storage`,
    p43: `Cross-selling store`,
    p44: `Automatic submission of parts to authorities`,
    p45: `PMS reservation synchronization`,
    p46: `Continue`,
        
    // Additional Services

    p50:`Select additional services for your plan`,
    p51:`Recommended`,
    p52:`WhatsApp`,
    p53:`We invite your guests to check in via scheduled WhatsApp
    before they arrive at your accommodation.`,
    p54:`apartment`,
    p55:`villa`,
    p56:`square`,
    p57:`room`,
    p58:`Difference in WhatsApp usage`,
    p59:`Using our WhatsApp`,
    p60:`By using our WhatsApp service, the name that will appear when sending
    messages to your guests will be QRCheckin. We handle message management
    to ensure effective communication with your guests.`,
    p61:`If you use our service with your own WhatsApp number, the name that
    will appear when sending messages will be your WhatsApp and you can
    have your verification badge. The sending cost will be subject to your
    WhatsApp Business with Meta's consumption.`,
    p62:`Using your WhatsApp Business`,
    p63:`Ratings`,
    p64:`Seize this opportunity to improve your guests' experience and
    get more positive reviews!`,
    p65:`Benefits`,
    p66:`At the end of their stay, we will send your guests a rating test
    via WhatsApp, significantly boosting your property's online reputation.
    Our service is designed to increase your positive reviews.`,
    p67:`Remote access`,
    p68:`We send your guests a virtual key to access the property autonomously.`,
    p69:`Benefits`,
    p70:`Your guests will receive the access key to their room securely and
    conveniently once they have completed their check-in, providing them
    with a hassle-free experience and improving access management efficiency.`,
    p71:`Identity verification`,
    p72:`We verify your guests' identity through our biometric comparison software.`,
    p73:`Benefits`,
    p74:`We verify your guests' identity through our biometric comparison software,
    ensuring that their documentation matches the person accessing your accommodation.`,


}