export const precios = {
    p1: `Seleciona a subscrição`,
    p2: `Encontra o plano perfeito e começa a usar o QRCheckin hoje mesmo`,
    p3: `Tipo de Subscrição`,
    p4: `Apartamento turístico`,
    p5: `mês`,
    p6: `Propriedades`,
    p7: `Anual`,
    p8: `Mensal`,
    p9: `por cada propriedade`,
    p10: `anual`,
    p11: `mensal`,
    p12: `Começar`,
    p13: `preço total -`,
    p14: `Hotel - Hostel`,
    p15: `Quartos`,
    p16: `por cada quarto`,
    p17: `Camping`,
    p18: `Villa`,
    p19: `Poupa até 20%`,
    p20: `O "alojamento"`,
    p21: `baseia-se em casas ou apartamentos individuais. Para os`,
    p22: `"aparthotéis"`,
    p23: `cada apartamento conta como um único alojamento`,
    p24: `Conta como`,
    p25: `Quartos`,
    p26: `no caso de hotéis, como de camas no caso de hostels e como`,
    p27: `pessoas`,
    p28: `no caso de campings.`,
    p29: `Por exemplo:`,
    p30: `Um hostel com 4 quartos e 20 camas, conta como 20 unidades de alojamento`,
    p31: `PERSONALIZADO`,
    p32: `mês`,
    p33: `por`,
    p34: `quarto`,
    p35: `apartamento`,
    p36: `Detalhes do plano`,
    p37: `Check-in presencial através de QR`,
    p38: `Check-in através de link enviado previamente`,
    p39: `Mensagem de boas-vindas por email`,
    p40: `Assinatura de políticas biométricas`,
    p41: `Scanner de BI - Passaporte`,
    p42: `Armazenamento ilimitado na nuvem`,
    p43: `Loja de vendas cruzadas`,
    p44: `Envio automático de partes às autoridades`,
    p45: `Sincronização de reservas PSM`,
    p46: `Continuar`,
    p50: `Seleciona serviços adicionais para o teu plano`,
    p51: `Recomendado`,
    p52: `WhatsApp`,
    p53: `Convida os teus hóspedes a fazerem o check-in através do WhatsApp programado
    antes de chegarem ao teu alojamento.`,
    p54: `apartamento`,
    p55: `villa`,
    p56: `vaga`,
    p57: `quarto`,
    p58: `Diferença no uso do WhatsApp`,
    p59: `Usando o nosso WhatsApp`,
    p60: `Ao usar o nosso serviço de WhatsApp, o nome que aparecerá ao enviar
    mensagens aos teus hóspedes será QRCheckin. Nós tratamos da gestão das
    mensagens para garantir uma comunicação eficaz com os teus hóspedes.`,
    p61: `Se utilizarem o nosso serviço com o vosso próprio número de WhatsApp, o nome que
    aparecerá ao enviar mensagens será o do vosso WhatsApp e poderão contar com a
    vossa insígnia de verificação. O custo do envio estará sujeito ao consumo do vosso
    WhatsApp Business com o Meta.`,
    p62: `Usando o teu WhatsApp Business`,
    p63: `Avaliações`,
    p64: `Aproveita esta oportunidade para melhorar a experiência dos teus hóspedes e
    obter mais avaliações positivas!`,
    p65: `Benefícios`,
    p66: `Após a estadia, enviaremos aos teus hóspedes um questionário de avaliação através do
    WhatsApp, o que aumentará significativamente a reputação online
    do teu alojamento. O nosso serviço foi concebido para aumentar as tuas
    avaliações positivas.`,
    p67: `Acesso remoto`,
    p68: `Enviamos aos teus hóspedes uma chave virtual para acederem à propriedade de
    forma autónoma.`,
    p69: `Benefícios`,
    p70: `Os teus hóspedes receberão a chave de acesso ao seu quarto de forma segura e
    conveniente assim que concluírem o seu check-in, proporcionando-lhes uma
    experiência sem complicações e melhorando a eficiência na gestão de
    acessos.`,
    p71: `Verificação de identidade`,
    p72: `Verificamos a identidade dos teus hóspedes através do nosso software de
    comparação biométrica.`,
    p73: `Benefícios`,
    p74: `Verificamos a identidade dos teus hóspedes através do nosso software de
    comparação biométrica, garantindo que a sua documentação corresponde à
    pessoa que está a aceder à tua propriedade.`,
}
