export const mensajeautomatico = {
    p1: `Automated Messages..`,
    p2: `Make Your Hotel Unforgettable with Personalized and Scheduled Messages.`,
    p3: `Optimize Your Check-in Now`,
    p4: `"Personalized Messages, Unforgettable Arrivals: The Magic of Auto Check-in with QRCHECKIN`,
    p5: `Discover how you can offer your visitors a hassle-free experience.`,
    p6: `Auto Check-in Via WhatsApp`,
    p7: `Programmable Automated Messages`,
    p8: `Hotel Services Information`,
    p9: `Tailored Customization`,
    p10: `Automatic Reminders via WhatsApp`,
    p11: `Get Started`,
    p12: `Tailor each message to your hotel's unique identity.`,
    p13: `From welcome messages to specific details`,
    p14: `We facilitate the arrival process by allowing your guests to check in 
    with a simple WhatsApp link. Eliminate queues and offer quick and comfortable entry.`,
    p15: ``,
}