export const nav = {
    p1: `Funcionalidades`,
    p2: `Integrações`,
    p3: `Preços`,
    p4: `Ver vídeo`,
    p5: `Registar`,
    p6: `Iniciar`,
    
    p7: `QR Check-in`,
    p8: `Os teus hóspedes poderão fazer check-in à chegada através da digitalização do código QR da tua propriedade.`,
    
    p9: `Check-in Online`,
    p10: `Os teus hóspedes receberão um link para fazer check-in antes de chegarem à tua propriedade.`,
    
    p11: `Boas-vindas via WhatsApp`,
    p12: `Oferece uma mensagem de boas-vindas através do WhatsApp com as informações do check-in de forma automática.`,
    
    p13: `Venda Cruzada`,
    p14: `Os teus hóspedes terão no seu WhatsApp acesso aos produtos, serviços ou tours que ofereces na tua propriedade.`,
    
    p15: `Envio às autoridades`,
    p16: `Enviamos de forma automática os relatórios de viajantes às autoridades locais.`,
    
    p17: `Mensagens automáticas`,
    p18: `Estabelece mensagens automáticas que serão enviadas em horas específicas: (ex. Enviar o menu do restaurante às 11h00.)`,
    p21: ``,
    p22: ``,
}
