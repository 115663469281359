export const selfcheckin = {
    p1: `Self Check-in`,
    p2: `Forget about handing over keys in person and save hundreds of hours with our self check-in feature, eliminating the need to travel to the property.`,
    p3: `Start Now`,
    p4: `Can you imagine never having to travel to deliver keys to your properties again?`,
    p5: `Your guests will enjoy the convenience and autonomy of self check-in`,
    p6: `Forget about adapting to your guests' arrival schedules.`,
    p7: `Guest registration and access are completely automatic.`,
    p8: `Enhance your guests' experience with flexible arrival times.`,
    p9: `Say goodbye to physical key losses.`,
    p10: `Manage multiple bookings on the same day without complications.`,
    p11: `Get Started`,
    p12: `Discover the self check-in revolution.`,
    p13: `Advantages of Self Check-in`,
    p14: `By using Self Check-in, travelers can save time and avoid the long lines that often form at airports and hotels. Additionally, this tool allows for greater flexibility in the check-in process, as it can be done at any time and from anywhere, as long as there is an internet connection. In summary, Self Check-in is an excellent option for travelers seeking a more comfortable, efficient, and flexible experience in their travels.`,
    p15: `Terms and
    Conditions`,
    p16: ``,
    p17: ``,

}