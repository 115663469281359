<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalWhatsapp"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div
          v-if="LoaderLocal"
          class="bloqueValidate d-flex flex-column"
          v-animate-css="'fadeIn'"
          style="padding: 30px"
        >
          <div class="parteNoverificada" v-if="!enviando">
            <span class="loader"></span>
            <div class="textBloque">{{ $t("home.p102") }}</div>
            <small>{{ $t("home.p103") }}</small>
          </div>
          <div class="parteNoverificada" v-if="enviando" v-animate-css="'fadeIn'">
            <img src="/marca/panda4.svg" alt="" style="width: 150px" />
            <br />

            <div class="textBloque">{{ $t("home.p104") }}</div>

            <small>
              {{ $t("home.p105") }}
            </small>
          </div>
        </div>

        <!-- <div class="efecto d-flex flex-column" v-if="loader">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <br />
          <strong>{{ mensajeLoader }}</strong>
        </div> -->

        <div class="modal-header" style="border-bottom: none">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: left; padding: 20px">
          <div class="d-flex flex-row" style="justify-content: center">
            <h1 class="pequeXS">{{ $t("home.p106") }}</h1>
            <div style="margin-left: 15px">
              <img src="/whatsapp.png" alt="" style="width: 40px" />
            </div>
          </div>

          <small style="font-size: 13px">
            {{ $t("home.p107") }}
            <!-- Envía un link demo para probar el proceso de check-in que harán tus huéspedes. -->
          </small>
          <!-- <br /><br />
          <small
            ><strong
              >Escribe tus datos para recibir en tu WhatsApp nuestro proceso demo de
              checkin.</strong
            ></small
          >
          <br /> -->

          <br />
          <br />

          <div class="bloqueVerificacion" v-if="$parent.propiedad">
            <strong> {{ $t("home.p108") }} </strong>
            <div class="custom-control custom-switch" style="cursor: pointer">
              <input
                style="cursor: pointer"
                type="checkbox"
                class="custom-control-input"
                :id="'switch' + $parent.propiedad.id"
                v-model="$parent.propiedad.r_selfie"
                @change="ChangeState()"
              />

              <label
                style="cursor: pointer"
                class="custom-control-label"
                :for="'switch' + $parent.propiedad.id"
              >
                <template v-if="$parent.propiedad.r_selfie">
                  {{ $t("home.p97") }}
                </template>
                <template v-else> {{ $t("home.p98") }} </template>
              </label>
            </div>
          </div>

          <div class="formulario" style="text-align: left; padding-top: 0">
            <br />
            <div class="form-group" style="margin-top: 9px">
              <small>{{ $t("home.p109") }} </small>
              <input
                style="padding: 29px 34px"
                type="text"
                class="form-control"
                v-model="nombre"
              />
            </div>
            <div class="form-group">
              <small>{{ $t("home.p110") }} </small>
              <input
                style="padding: 29px 34px"
                type="email"
                class="form-control"
                v-model="email"
              />
            </div>
            <div class="form-group">
              <small>{{ $t("home.p111") }} </small>
              <VuePhoneNumberInput
                id="phoneNumber1"
                v-model="phoneNumber"
                :default-country-code="'ES'"
                color="dodgerblue"
                :translations="{
                  countrySelectorLabel: 'Pais',
                  countrySelectorError: 'Error',
                  phoneNumberLabel: '',
                  example: '',
                }"
                :loader="hasLoaderActive"
                clearable
                :error="hasErrorActive"
                class="form-control input-normal"
                @update="onUpdate"
                style="padding: 0; width: 100%; background: #fcfcfc; border-radius: 10px"
              />
            </div>
            <div class="form-group grandeXS" style="float: right">
              <button
                @click.prevent="enviarCheckin()"
                class="btn grandeXS"
                style="
                  color: white;
                  background: rgb(17, 177, 77);
                  border-radius: 10px;
                  font-weight: 600;
                  box-shadow: #1f962382 0px 10px 25px;
                  font-size: 16px;
                  line-height: 24px;
                  transition: color, background-color 0.15s ease-in-out;
                  padding: 16px 25px;
                "
              >
                {{ $t("home.p112") }} <i class="fas fa-paper-plane ml-3"></i>
              </button>
            </div>
          </div>

          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  mounted() {},

  data() {
    return {
      LoaderLocal: false,
      phoneNumber: "",
      defaultCountry: "FR",
      countriesList: ["FR", "BE", "DE"],
      countriesIgnored: ["AF", "AD", "AL"],
      dark: false,
      disabled: false,
      hasLoaderActive: false,
      hasErrorActive: false,
      telefono: "",
      email: null,
      nombre: null,
      mensajeLoader: "Cargando ...",
      enviando: false,
    };
  },

  components: { VuePhoneNumberInput },
  methods: {
    ChangeState() {
      let url = "https://app.wacheckin.com/api/cambio/state/example";

      axios
        .post(url, this.$parent.propiedad, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log("Se cambió el estado correctamente");
        })
        .catch((e) => {
          toastr.error("Error al activar la verificación de identidad");
        });
    },

    enviarCheckin() {
      if (!this.nombre) {
        toastr.error("El nombre es requerido");
        return;
      }

      if (!this.email) {
        toastr.error("El correo electrónico es requerido");
        return;
      }
      if (!this.telefono) {
        toastr.error("El teléfono es requerido");
        return;
      }

      var _vm = this;
      // this.loader = true;
      // this.mensajeLoader = "Enviando link de check-in por favor espere...";

      this.enviando = false;
      this.LoaderLocal = true;

      setTimeout(() => {
        this.mensajeLoader = "Falta poco, por favor espere...";
      }, 3000);

      let url = "https://api.qrcheckin.pro/api/send/checkin/previe";

      var mensaje = `🇪🇸 Bienvenido ${this.nombre} a  Hotel Demo Vamos a hacer el checkin en nuestro alojamiento, evitando así posibles esperas en recepción y el consumo deliberado de papel. 

Escribe ‘Ok’ para continuar. 

🇺🇸 Welcome ${this.nombre} to Hotel Demo Let's go to check-in at our accommodation, avoiding possible queues at reception and deliberate paper consumption.

Write  ‘Ok’ to continue 
         `;

      var link_w = "";

      var mensaje_correo = "Hola ";
      this.nombre +
        "! Somos Hotel Demo" +
        ". Te enviamos este mensaje para que puedas hacer Check-in previo en nuestro alojamiento, evitando así posibles esperas en recepción y el consumo deliberado de papel. <br>  <br> Por un planeta más verde!";
      var link = " https://app.wacheckin.com/checkin/customers/previe/10/property";

      const datos = {
        nombre_propiedad: "Hotel Demo",
        nombre: this.nombre,
        habitacion_id: 2,
        tipo: 1,
        email: this.email,
        telefono: this.telefono.replace("+", ""),
        propiedad_id: 10,
        cantidad_huesped: 1,
        fecha_ingreso: this.moment().format("YYYY-MM-DD"),
        fecha_salida: this.moment().add(1, "days").format("YYYY-MM-DD"),
        mensaje: mensaje,
        user_id: 22,
        mensaje_correo: mensaje_correo,
        link: link,
        link_w: link_w,
      };

      axios
        .post(url, datos)
        .then((e) => {
          // setTimeout(() => {
          //   this.mensajeLoader = "Cargando";
          // }, 6000);

          this.enviando = true;
          var _vm = this;

          setTimeout(() => {
            $("#modalWhatsapp").modal("hide");
            _vm.LoaderLocal = false;
          }, 2800);

          // this.loader = false;
          // toastr.success("Link de check-in enviado con éxito");

          // this.EnvioMensajeWhatsapp(datos);
        })
        .catch((e) => {
          // this.loader = false;
          this.enviando = false;
          this.LoaderLocal = false;
          toastr.error(`Error al enviar el check-in`);
        });
    },
    EnvioMensajeWhatsapp(info) {
      let url = "https://api.ultramsg.com/instance8513/messages/chat";

      const datos = {
        token: "k8whhkrnvrof6i5v",
        to: this.telefono,
        body: info.mensaje + info.link_w,
        priority: "10",
        referenceId: "",
      };

      axios
        .post(url, datos)
        .then((res) => {
          console.log("mensaje enviado");
        })
        .catch((e) => {
          console.log(e);
        });
    },

    onUpdate(payload) {
      this.telefono = payload.e164;
    },
  },
};
</script>

<style lang="scss" scoped>
.bloqueValidate {
  position: absolute;
  top: 70px;
  z-index: 10;
  background: white;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-inline: 16px;
}
.textBloque {
  font-size: 27px;
}
.textBluqu2 {
}
.textBloue3 {
  color: red;
}

.loader {
  // position: absolute;
  // left: 0;
  // right: 0;
  // margin: auto;
  bottom: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border: 5px solid;
  border-color: #4caf50 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

<style scoped>
.efecto {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffffe6;
  z-index: 1;
  justify-content: center;
  align-items: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #4caf50;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4caf50 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
@media (max-width: 768px) {
  .pequeXS {
    font-size: 30px !important;
    text-align: left !important;
  }
  .grandeXS {
    width: 100% !important;
  }
}
.country-selector {
  height: 57px !important;
  min-height: 57px !important;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.country-selector__country-flag {
  margin: auto 0;
  position: absolute;
  top: 26px !important;
  left: 11px;
  z-index: 1;
  cursor: pointer;
}

.country-selector__input {
  cursor: pointer;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 57px !important;
  min-height: 40px;
  padding-right: 22px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #ccc;
  font-size: 13px;
  z-index: 0;
  padding-left: 8px;
  color: #000;
}

.input-tel__input {
  cursor: pointer;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  padding: 0 12px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #ccc;
  font-size: 14px;
  z-index: 0;
  margin-left: -1px;
  height: 57px !important;
  min-height: 40px;
}
</style>
