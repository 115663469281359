export const police = {
    p1: `Envio à polícia`,
    p2: `Cumpra automaticamente as normas e regulamentos do seu país com o nosso sistema de envio de check-ins à polícia!`,
    p3: `Começar`,
    p4: `Envio automático às autoridades competentes`,
    p5: `Vantagens de utilizar o nosso serviço automático`,
    p6: `O nosso sistema integra-se perfeitamente com o seu sistema de gestão de propriedades existente, o que significa que não há necessidade de alterar os seus processos atuais.`,
    p7: `Em caso de emergência ou situação de segurança, a polícia migratória terá acesso imediato aos check-ins dos seus hóspedes, o que pode ajudar a acelerar a sua resposta e proteger os seus hóspedes.`,
    p8: `Ao fornecer informações importantes à polícia migratória, o nosso sistema ajuda a manter os seus hóspedes seguros e protegidos durante a sua estadia na sua propriedade.`,
    p9: `O nosso serviço automatizado de envio de check-ins à polícia migratória ajudará a cumprir as regulamentações locais, evitando possíveis multas ou sanções.`,
    p10: `Começar`,
    p11: `Poupe tempo e dores de cabeça`,
    p12: `Envio automático de partes de viajantes às autoridades`,
    p13: `Funciona da seguinte forma: Uma vez que os seus hóspedes tenham completado o
    check-in online, geramos as partes de viajante e as enviamos
    automaticamente às autoridades competentes durante as 24 horas seguintes
    à data de entrada, conforme exigido por lei.`,
    p14: `Termos e
    condições`,
    p15: ``,
    p16: ``,
    p17: ``,
    p18: ``,
    p19: ``,
}
