export const legalidad = {
    p1: `Legalidad`,
    p2: `Nos encargamos de todos los procesos legales para que no tengas que preocuparte.
    Ahorra tiempo, dinero, recursos y problemas potenciales con la ley`,
    p3: `Comenzar`,
    p4: `Te evitamos multas asegurando de que cumplas con la legalidad vigente en más de`,
    p5: `130 países.`,
    p6: `Activa la funcionalidad de Legalidad y olvídate.`,
    p7: `Enviamos automáticamente los partes de viajeros a las autoridades.`,
    p8: `Estamos integrados con la web de las autoridades de cada localidad para
    que el envío sea 100% automático.`,
    p9: `Todos los documentos quedarán almacenados y a tu disposición el tiempo
    requerido por ley.`,
    p10: `Personaliza el contrato de arrendamiento incluyendo tus normas de la casa
    para que lo firmen durante el check-in online.`,
    p11: `Generamos informes estadísticos y los enviamos automáticamente a los
    organismos competentes.`,
    p12: `Comenzar`,
    p13: `Ahorra trabajo, papel y preocupaciones`,
    p14: `Contratos personalizados y firma digital`,
    p15: `Di adiós al papel, tendrás todos tus documentos en formato digital en un mismo
    lugar. Podrás personalizar el contrato de arrendamiento de cada propiedad y
    añadir tantas cláusulas como desees. Durante el check-in online, tus huéspedes
    podrán firmar digitalmente el contrato, que quedará guardado en tu panel de
    control.`,
    p16: `Términos y
    condiciones`,
    p17: ``,
    p18: ``,
    p19: ``,
}