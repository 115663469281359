// Formatear precio 
//Funciones para  Formatear precio
export function formatearPrecio(precio, pais) {
    precio = Number(precio);
    if (isNaN(precio)) {
      return precio;
    }
  
    if (pais === 'Colombia') {
      // Formato para Colombia sin decimales
      return precio.toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, "$&.").replace(',', '.');
    } else if (pais === 'España') {
      // Formato para España con dos decimales
      return precio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.").replace(',', '.');
    } else {
      // Formato por defecto si el país no es especificado
      return precio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.").replace(',', '.');
    }
  }
  
  
  export function formatearPrecioGeneral(value) {
    value = Number(value);
    if (isNaN(value)) {
      return value;
    }
    let val = (value / 1).toFixed(2).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }