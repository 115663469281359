export const legalidad = {
    p1: `Legality`,
    p2: `We take care of all legal processes so you don't have to worry. Save time, money, resources, and potential legal issues.`,
    p3: `Get Started`,
    p4: `We prevent fines by ensuring compliance with current legality in more than`,
    p5: `130 countries.`,
    p6: `Activate the Legality feature and forget about it.`,
    p7: `We automatically send traveler reports to the authorities.`,
    p8: `We are integrated with the website of local authorities to ensure 100% automatic submission.`,
    p9: `All documents will be stored and available to you for the required time by law.`,
    p10: `Customize the lease agreement by including your house rules for guests to sign during online check-in.`,
    p11: `We generate statistical reports and automatically send them to the relevant agencies.`,
    p12: `Get Started`,
    p13: `Save work, paper, and worries`,
    p14: `Custom contracts and digital signatures`,
    p15: `Say goodbye to paper; you'll have all your documents in digital format in one place. You can customize the lease agreement for each property and add as many clauses as you want. During online check-in, your guests can digitally sign the contract, which will be saved in your control panel.`,
    p16: `Terms and
    conditions`,
    p17: ``,
    p18: ``,
    p19: ``,

}