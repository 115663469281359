export const upselling = {
    p1: `Ventas Cruzadas`,
    p2: `Aumenta tus beneficios ofreciendo experiencias personalizadas a tus huéspedes,
    ya sean propias o de terceros.`,
    p3: `Prueba gratis`,
    p4: `Enviamos el link de tus productos justo después de hacer el check-in`,
    p5: `Siempre tendrán el link a mano en su WhatsApp o Email`,
    p6: `Mejora la experiencia de tus huéspedes`,
    p7: `Incluye y personaliza tantas experiencias propias como quieras`,
    p8: `Ofrece a tus huéspedes una amplia gama de experiencias de terceros.`,
    p9: `Aumenta tus beneficios con cada reserva`,
    p10: `Añade valor a la propuesta de tu alojamiento turístico`,
    p11: `Huéspedes contentos, mejores reseñas`,
    p12: `Prueba gratis`,
    p13: `Términos y
    condiciones`,
    p14: ``,
    p15: ``,
    p16: ``,
    p17: ``,
    p18: ``,
    p19: ``,
    p20: ``,
    p21: ``,
}