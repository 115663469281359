export const qrcheckin = {
    p1: `Check-in QR`,
    p2: `Elimine as longas esperas na receção. Com o nosso sistema de check-in online, os seus hóspedes podem completar o processo a partir do conforto do seu dispositivo.`,
    p3: `Experimente gratuitamente`,
    p4: `Descubra a conveniência e eficiência do check-in online`,
    p5: `Estamos aqui para transformar a forma como os seus hóspedes iniciam a sua experiência no seu hotel`,
    p6: `Sem Filas, Sem Esperas`,
    p7: `Acesso Instantâneo via WhatsApp`,
    p8: `Personalização Total`,
    p9: `Check-ins em dois passos, fácil e rápido`,
    p10: `Mensagens Automáticas Programadas`,
    p11: `Começar`,
    p12: `Simplifique a chegada dos seus hóspedes`,
    p13: `Comece a construir experiências memoráveis desde o primeiro momento`,
    p14: `Permita que os seus hóspedes façam o seu check-in de forma rápida e sem complicações através de um link via WhatsApp`,
    p15: 'Bem-vindo ao Futuro da Chegada sem Stress',
}
