export const legalidad = {
    p1: `Legalidade`,
    p2: `Encarregamo-nos de todos os processos legais para que não tenhas que te preocupar.
    Poupa tempo, dinheiro, recursos e possíveis problemas legais`,
    p3: `Começar`,
    p4: `Evitamos multas garantindo que cumpras com a legalidade vigente em mais de`,
    p5: `130 países.`,
    p6: `Ativa a funcionalidade de Legalidade e esquece.`,
    p7: `Enviamos automaticamente os relatórios de viajantes às autoridades.`,
    p8: `Estamos integrados com o website das autoridades de cada localidade para
    que o envio seja 100% automático.`,
    p9: `Todos os documentos ficarão armazenados e à tua disposição pelo tempo
    exigido por lei.`,
    p10: `Personaliza o contrato de arrendamento incluindo as tuas regras da casa
    para que o assinem durante o check-in online.`,
    p11: `Geramos relatórios estatísticos e os enviamos automaticamente para os
    organismos competentes.`,
    p12: `Começar`,
    p13: `Poupa trabalho, papel e preocupações`,
    p14: `Contratos personalizados e assinatura digital`,
    p15: `Diz adeus ao papel, terás todos os teus documentos em formato digital num único
    local. Podes personalizar o contrato de arrendamento de cada propriedade e
    adicionar tantas cláusulas quantas desejares. Durante o check-in online, os teus hóspedes
    poderão assinar digitalmente o contrato, que ficará guardado no teu painel de
    controlo.`,
    p16: `Termos e
    condições`,
    p17: ``,
    p18: ``,
    p19: ``,
}
