<template>
<div class="Landing container-fluid" style="background-image: url('./fondo.png')">
    <div class="lg">
        <Nav />
    </div>
    <div class="xs">
        <NavMovil />
    </div>

    <Content />
</div>
</template>

<script>
import Content from "./Landing/Content.vue";
import Nav from "./Landing/Nav.vue";
import NavMovil from "./Landing/navMovil.vue";

// @ is an alias to /src

export default {
    name: "HomeView",
    components: {
        Nav,
        Content,
        NavMovil,
    },
};
</script>

<style scoped>
@media (max-width: 768px) {
    .Landing {
        background-color: #f8f9fc;
        background-repeat: no-repeat;
    }
}

@media (min-width: 768px) {
    .Landing {
        background-size: contain;
        background-color: #fff;
        background-repeat: no-repeat;
    }
}

.CuHeaderBlob_blob__RmLwX,
.CuHeaderBlob_wrapper__KLBAh {
    position: absolute;
    inset-block-start: 0;
    pointer-events: none;
    right: -51vw;
}

.CuHeaderBlob_wrapper__KLBAh {
    z-index: 0;
    overflow-x: hidden;
    inline-size: 100vw;
    max-inline-size: 100%;
    block-size: 100%;
    max-block-size: 100%;
}

.CuHeaderBlob_default__IoIWy[data-v-9ea40744] {
    inset-inline-start: 50%;
    max-inline-size: 1149px;
    block-size: 1143px;
    background: #4caf50;
    background-image: radial-gradient(circle at 10% 30%, #2e7d32, rgba(122, 27, 221, 0) 55%),
        radial-gradient(circle at 40% 70%, #4caf50 10%, rgba(71, 152, 223, 0) 50%),
        radial-gradient(circle at 100% 50%, #2196f3, rgba(255, 140, 50, 0) 60%),
        radial-gradient(circle at 50% 20%, #006e04, rgba(201, 54, 161, 0));
    background-size: 1149px 1143px;
    transform: translate(150px, -230px);
    -webkit-clip-path: path("M49.6107 585.633C-42.9903 441.629 -1.31937 249.822 142.685 157.221L309.456 49.9806C453.46 -42.6203 645.267 -0.949305 737.868 143.055L856.588 327.678C949.189 471.683 907.518 663.49 763.514 756.091L596.743 863.331C452.739 955.932 260.932 914.261 168.331 770.257L49.6107 585.633Z"
        );
    clip-path: path("M49.6107 585.633C-42.9903 441.629 -1.31937 249.822 142.685 157.221L309.456 49.9806C453.46 -42.6203 645.267 -0.949305 737.868 143.055L856.588 327.678C949.189 471.683 907.518 663.49 763.514 756.091L596.743 863.331C452.739 955.932 260.932 914.261 168.331 770.257L49.6107 585.633Z"
        );
}
</style>
