export const pagewhatsapp = {
    p1: `WhatsApp de Bienvenida`,
    p2: `Ofrezca un mensaje de bienvenida personalizado a través de WhatsApp al finalizar
    el proceso de check-in y haga que sus huéspedes se sientan valorados y
    especiales desde el primer momento.`,
    p3: `Comienza`,
    p4: `¡Diferénciese de su competencia y aumente la satisfacción y fidelidad de sus
    clientes con nuestro servicio innovador!`,
    p5: `Ventajas de utilizar Whatsapp en el proceso de check-in.`,
    p6: `Ofrecer un mensaje de bienvenida personalizado a través de WhatsApp crea
    una experiencia única y especial para cada huésped.`,
    p7: `Ofrezca un valor añadido a sus huéspedes y diferénciese de la competencia,
    mejorando la satisfacción de sus clientes.`,
    p8: `Reduzca el tiempo y los recursos necesarios para realizar el check-in,
    automatizando el proceso y ofreciendo una experiencia más eficiente y
    rápida para sus huéspedes.`,
    p9: `Al ofrecer un servicio innovador y conveniente, se mejora la satisfacción
    y fidelidad de los huéspedes, lo que puede generar un impacto positivo en
    la reputación y el éxito sus propiedades.`,
    p10: `Comenzar`,
    p11: `Revolucione su check-in`,
    p12: `Transforme la experiencia de sus huéspedes y aumente su éxito de su negocio.`,
    p13: `Los huéspedes recibirán automáticamente un mensaje de bienvenida personalizado,
    Tú tienes la oportunidad de agregar un toque especial, personalizando el mensaje
    para cada uno de ellos.`,
    p14: `Términos y
    condiciones`,
    p15: ``,
    p16: ``,
}