export const selfcheckin = {
    p1: `Self Check-in`,
    p2: `Olvídate de entregar llaves en persona y ahorra cientos de horas con nuestra
    funcionalidad de self check-in, eliminando la necesidad de desplazarte hasta la
    propiedad.`,
    p3: `Empieza Ahora`,
    p4: `¿Te imaginas nunca más tener que desplazarte para entregar llaves a tus
    propiedades?`,
    p5: `Tus huéspedes disfrutarán de la comodidad y autonomía del self check-in`,
    p6: `Olvídate de adaptarte a los horarios de llegada de tus huéspedes.`,
    p7: `El registro de huéspedes y el acceso son completamente automáticos.`,
    p8: `Mejora la experiencia de tus huéspedes con horarios de llegada flexibles.`,
    p9: `Dile adiós a las pérdidas de llaves físicas.`,
    p10: `Gestiona múltiples reservas en el mismo día sin complicaciones.`,
    p11: `Comenzar`,
    p12: `Descubre la revolución del self check-in.`,
    p13: `Ventajas del Self Check-in`,
    p14: `Al utilizar el Self Check-in, los viajeros pueden ahorrar tiempo y evitar las largas filas que suelen formarse en las terminales aéreas y hoteles. Además, esta herramienta permite una mayor flexibilidad en el proceso de check-in, ya que se puede realizar en cualquier momento y desde cualquier lugar, siempre y cuando se cuente con una conexión a internet. En resumen, el Self Check-in es una excelente opción para aquellos viajeros que buscan una experiencia más cómoda, eficiente y flexible en sus viajes.`,
    p15: `Términos y
    condiciones`,
    p16: ``,
    p17: ``,
}