export const precios_update = {
    text1: "Método de pago",
    text2: "Tarjeta de crédito",
    text3: "No tiene métodos de pago registrados",
    text4: "Edita tu método de pago",
    text5: "Añadir método de pago",
    text6: "Añade un método de pago",
    text7: "Actualizar un método de pago",
    text8: "Registra o actualiza el método de pago",
    text9: "Numero de tarjeta:",
    text10: "Exp",
    text11: "CVC",
    text12: "Guardar tarjeta",
    text13: "Número invalido",
    text14: "Invalido",
    text15: "Invalido CVC.",
    text16: "Algo salio mal",
    text17: "Consumo",
    text18: "Mis facturas",
    text19: "Desde - Hasta",
    text20: "ID",
    text21: "Valor",
    text22: "Entidad que emite la factura",
    text23: "BIG NERDS COMPANY L.L.C.",
    text24: "Fecha de emisión",
    text25: "Estado",
    text26: "Descargar Factura",
    text27: "Generando Factura ...",
    text28: "Casi termina por favor espere ...",
    text29: "Cargando ...",
    text30: "Disculpe la factura no se pudo generar",
    text31: "Seguro quieres borrar el método de pago?",
    text32: "Perderás los datos guardados",
    text33: "Eliminar método",
    text34: "Cancelar",


    // Correciones 
    p1:`Suscripción`,
    p2:`Tu Suscripción`,
    p3:`FREE`,
    p4:`PERSONALIZADO`,
    p5:`No tienes una suscripción activa. ¡Vamos a hacer algo al respecto!`,
    p6:`Caduca: `,
    p7:`Elige un plan`,
    p8:`Modifica tu suscripción`,
    p9:`Número de alojamientos`,
    p10:`Por propiedad`,
    p11:`Administrar suscripción`,
    p12:`Ajustes de pago`,
    p13:`Caduca: `,
    p14:`Editar`,
    p15:`Renovación automática`,
    p16:`Activado`,
    p17:`El próximo pago está programado el`,
    p18:`Facturas y recibos`,
    p19:`PERSONALIZADO`,
    p20:`Ver factura`,
    p21:`Descargar factura`,
    p22:`por`,


    p23:`Mensual`,
    p24:`Anual`,
    p25:`Te regalamos 2 meses`,
    p26:`Tipo de Suscripción`,

    p27:`Apartamento turístico`,
    p28:`Hotel - Hostal`,
    p29:`Camping`,
    p30:`Villa`,

    p31:`La <strong>"propiedad"</strong> se basa en las casas o apartamentos individuales.
      Para los <strong>"apartahotel"</strong> , cada apartamento cuenta como una sola
      propiedad`,
    p32:`Cuenta como
    <strong>Habitaciones</strong> en caso de hoteles, como de camas en caso de hostales
    y como <strong>personas </strong> en caso de camping.

    <br />
    <strong>Por ejemplo: </strong>Un hostal de 4 habitaciones y 20 camas, contará como
    20 unidades alojativas`,


    p33:`PREMIUM`,
    p34:`mes`,
    p35:`habitación`,
    p36:`apartamento`,
    p37:`Detalles del plan`,
    p38:`Check-in in situ mediante QR`,
    p39:`Check-in mediante link previo envío`,
    p40:`Mensaje bienvenida email`,
    p41:`Firma de políticas biométrica`,
    p42:`Escáner de DNI - Pasaporte`,
    p43:`Almacenamiento ilimitado en la nube`,
    p44:`Tienda ventas cruzadas`,
    p45:`Envío automático de partes a autoridades`,
    p46:`Sincronización de reservas PMS`,
    p47:`Actualizar suscripción`,
    p48:`Continuar`,
    p49:`por`,

    p50:`Selecciona servicios adicionales a tu plan`,
    p51:`Recomendado`,
    p52:`WhatsApp`,
    p53:`Invitamos a realizar check-in a tus huéspedes mediante WhatsApp programado
    antes de que lleguen a tu alojamiento.`,
    p54:`apartamento`,
    p55:`villa`,
    p56:`plaza`,
    p57:`habitación`,
    p58:`Diferencia del uso de WhatsApp`,
    p59:`Usas nuestro número de WhatsApp.`,
    p60:`El nombre que aparecerá al huésped al recibir mensajes  será WACheckin No tiene coste adicional. Este servicio está solo disponible para alojamientos de hasta 50 plazas.`,
    p61:`El nombre que aparecerá al enviar mensajes será el de tu WhatsApp Business, podrás personalizar tu perfil de WhatsApp e incluso podrás verificar y contar con su insignia de verificación verde. El costo de envío estará sujeto al consumo de su WhatsApp Business con Meta. Actualmente el costo es de 0.035 por conversación (checkin) y tú pagas directamente a Meta.`,
    p62:`Usas tu propio número de WhatsApp`,
    p63:`Valoraciones`,
    p64:`¡Aprovecha esta oportunidad para mejorar la experiencia de tus huéspedes y
    obtener más valoraciones positivas!"`,
    p65:`Beneficios`,
    p66:`Al finalizar la estancia, enviaremos a tus huéspedes un test de valoración a
    través de WhatsApp, lo que impulsará significativamente la reputación en línea
    de tu alojamiento. Nuestro servicio está diseñado para aumentar tus
    valoraciones positivas.`,
    p67:`Acceso remoto`,
    p68:`Enviamos a tus huéspedes una llave virtual para que accedan a la propiedad de
    forma autónoma.`,
    p69:`Beneficios`,
    p70:`Sus huéspedes recibirán la clave de acceso a su habitación de forma segura y
    conveniente una vez que hayan completado su check-in, brindándoles una
    experiencia sin complicaciones y mejorando la eficiencia en la gestión de
    accesos.`,
    p71:`Verificación de identidad`,
    p72:`Comprobamos la identidad de tus huéspedes mediante nuestro software de
    comparación biométrica`,
    p73:`Beneficios`,
    p74:`Comprobamos la identidad de tus huéspedes mediante nuestro software de
    comparación biométrica, asegurando que su documentación corresponde con la
    persona que accede a tu alojamiento.`,
    p75:`SUSCRÍBETE AHORA`,
    p76:`Almacenamiento ilimitado en la nube`,
    p77:`Personalizado`,
    p78:`Pregunte a nuestro equipo de ventas`,
    p79:`WhatsApp`,
    p80:`Verificación de Identidad`,
    p81:`Reviews (Valoraciones check-in)`,
    p82:`Conexión con API para Cerraduras Inteligentes`,
    p83:`Cobro con Tarjeta (para depósitos, servicios, etc)`,
    p84:`Conectar con ventas`,
    p85:`Comenzar`,
    p86:``,
    p87:``,
    p88:``,
    p89:``,





    
};