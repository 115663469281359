import { es_i18n } from "./es/es.js";
import { en_i18n } from "./en/en.js";
import { co_i18n } from "./co/co.js";
import { pt_i18n } from "./pt/pt.js";

export const translations = {
    es: es_i18n,
    en: en_i18n,
    co: co_i18n,
    ve: co_i18n,
    pt: pt_i18n,
};