<template>
  <div class="Bloque3 d-flex" ref="block">
    <div class="container fondo-imagen d-flex flex-column">
      <div class="medio">{{ $t("home.p77") }}</div>
      <div
        class="d-flex flex-row lit"
        style="
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 37px;
        "
      >
        <div class="div d-flex flex-column cantidad">
          <div class="d-flex flex-row" style="align-items: center">
            <span style="font-size: 40px">+</span>
            <div class="grande" ref="propiedades">0</div>
          </div>
          <div class="sub">{{ $t("home.p78") }}</div>
        </div>
        <div class="div divisor"></div>
        <div class="div d-flex flex-column cantidad">
          <div class="d-flex flex-row" style="align-items: center">
            <span style="font-size: 40px">+</span>
            <div class="grande" ref="checkins">0</div>
          </div>
          <div class="sub">{{ $t("home.p79") }}</div>
        </div>
        <div class="div divisor"></div>
        <div class="div d-flex flex-column cantidad">
          <div class="d-flex flex-row" style="align-items: center">
            <span style="font-size: 40px">+</span>
            <div class="grande" ref="paises">0</div>
          </div>
          <div class="sub">{{ $t("home.p80") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .medio {
    font-size: 26px !important;
  }
  .lit {
    margin-top: 0 !important;
    flex-direction: column !important;
  }

  .Bloque3 {
    .cantidad {
      padding-block: 13px;

      span {
        font-size: 27px !important;
      }

      .grande {
        font-size: 40px !important;
      }
    }
  }
}
.Bloque3 {
  background: rgb(10, 184, 79);
  background: radial-gradient(circle, rgba(10, 184, 79, 1) 0%, rgba(8, 158, 67, 1) 77%);
  height: 445px;
  margin-inline: -15px;

  .cantidad {
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
  }
  .divisor {
    height: 100%;
    width: 2px;
    background: #eee;
  }
  .medio {
    font-size: 40px;
    font-weight: 600;
    color: white;
  }

  .grande {
    font-size: 60px;
    font-weight: bold;
    color: white;
  }
}
.fondo-imagen {
  justify-content: center;
  align-items: center;
}
</style>
<script>
export default {
  data() {
    return {
      isAnimated: false,
    };
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);

    observer.observe(this.$refs.block);
  },
  methods: {
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !this.isAnimated) {
          this.animateNumbers();
          this.isAnimated = true; // Marcar como animado para evitar repeticiones
          observer.unobserve(entry.target);
        }
      });
    },
    animateNumbers() {
      this.animateNumber(this.$refs.propiedades, 0, 100, 1000);
      this.animateNumber(this.$refs.checkins, 0, 80000, 1000);
      this.animateNumber(this.$refs.paises, 0, 3, 1000);
    },
    animateNumber(target, from, to, duration) {
      const startTime = Date.now();
      const updateNumber = () => {
        const currentTime = Date.now();
        const progress = Math.min((currentTime - startTime) / duration, 1);
        const value = Math.floor(from + progress * (to - from));
        target.textContent = value.toLocaleString(); // Otra opción: usar Intl.NumberFormat para formatear números
        if (progress < 1) {
          requestAnimationFrame(updateNumber);
        }
      };
      updateNumber();
    },
  },
};
</script>
