export const checkin = {
    p1: `Check-in Online`,
    p2: `Uma vez confirmada a reserva, os seus hóspedes receberão um link que os
    levará ao check-in online, onde realizarão todo o processo de registo.
    Fácil, rápido e simples.`,
    p3: `Registe-se Grátis`,
    p4: `Um processo 100% automático,`,
    p5: `validado por quase 1 milhão de hóspedes.`,
    p6: `Um antes e um depois no seu processo de registo`,
    p7: `Melhora significativamente a experiência dos seus hóspedes.`,
    p8: `Reduza o tempo de espera seu e dos seus hóspedes durante o
    registo.`,
    p9: `Ofereça um check-in online único no setor.`,
    p10: `Esqueça os processos tediosos e manuais.`,
    p11: `Todos os dados gerados serão armazenados numa nuvem de segurança.`,
    p12: `Começar`,

    p13: `Especialmente concebido para cumprir todas as suas expectativas`,
    p14: `O processo do check-in online é muito simples e pode ser concluído em menos de 1
    minuto.`,
    p15: `Escanear QR`,
    p16: `Assim que os seus hóspedes chegarem ao hotel, encontrarão um código QR ou
    receberão um link direto para a nossa aplicação web de check-in online.`,
    p17: `Digitalizar Documento`,
    p18: `Nesta fase, será solicitado que digitalizem o seu documento de identificação
    válido, seja passaporte ou documento de identificação nacional.`,
    p19: `Assinar as políticas de Alojamento`,
    p20: `Uma vez digitalizado o documento, chegarão à etapa final do processo de
    check-in. Aqui poderão assinar eletronicamente utilizando o mesmo sistema.`,
    p21: `Termos e
    condições`,
    p22: ``,
    p23: ``,
    p24: ``,
    p25: ``,
}
