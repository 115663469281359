export const checkin = {
    p1: `Check-in Online`,
    p2: `Una vez se confirma la reserva, tus huéspedes recibirán un enlace que les
    conducirá al check-in online, donde realizarán todo el proceso de registro.
    Fácil, rápido y sencillo.`,
    p3: `Regístrate Gratis`,
    p4: `Un proceso 100% automático,`,
    p5: `validado por casi 1 millones de huéspedes.`,
    p6: `Un antes y un después en tu proceso de registro`,
    p7: `Mejora significativamente la experiencia de tus huéspedes.`,
    p8: `Reduce tu tiempo tiempo de espera y el de tus huéspedes durante el
    registro.`,
    p9: `Ofrece un check-in online único en el sector.`,
    p10: `Olvida los procesos tediosos y manuales.`,
    p11: `Todos los datos generados estarán almacenados en una nube de seguridad.`,
    p12: `Comenzar`,

    p13: `Especialmente diseñado para cumplir con todas tus expectativas`,
    p14: `El proceso del check-in online es muy sencillo y puede completarse en menos de 1
    minuto.`,
    p15: `Escanear QR`,
    p16: `Una vez tus huéspedes lleguen al hotel, encontrarán un código QR o
    recibirán un enlace directo a nuestra aplicación web de check-in online.`,
    p17: `Escanea Documento`,
    p18: `En esta etapa, solicitaremos que escaneen su documento de identidad
    válido, ya sea pasaporte o documento de identidad nacional.`,
    p19: `Firma las políticas del Alojamiento`,
    p20: `Una vez escaneado el documento, llegarán la etapa final del proceso de
    check-in. Aquí podrán firmar electrónicamente utilizando el mismo sistema.`,
    p21: `Términos y
    condiciones`,
    p22: ``,
    p23: ``,
    p24: ``,
    p25: ``,
}