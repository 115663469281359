export const precios = {
    p1: `Selecciona la suscripción`,
    p2: `Encuentra el plan perfecto y comienza a utilizar QRCheckin hoy mismo`,
    p3: `Tipo de Suscripción`,
    p4: `Apartamento turístico`,
    p5: `mes`,
    p6: `Propiedades`,
    p7: `Anual`,
    p8: `Mensual`,
    p9: `por cada propiedad`,
    p10: `anual`,
    p11: `mensual`,
    p12: `Comenzar`,
    p13: `precio total -`,
    p14: `Hotel - Hostal`,
    p15: `Habitaciones`,
    p16: `por cada habitación`,
    p17: `Camping`,
    p18: `Villa`,
    p19: `Ahorra hasta un 20%`,

    p20: `El "alojamiento"`,
    p21: `se basa en las casas o apartamentos individuales. Para los`,
    p22: `"apartahotel"`,
    p23: `cada apartamento cuenta como un solo alojamiento`,

    p24: `Cuenta como`,
    p25: `Habitaciones`,
    p26: ` en caso de hoteles, como de camas en caso de hostales y como`,
    p27: `personas`,
    p28: `en caso de camping.`,
    
    p29: `Por ejemplo:`,
    p30: `Un hostal de 4 habitaciones y 20 camas, contará como
    20 unidades alojativas`,


    // Correciones  
    
    p31: `PERSONALIZADO`,
    p32: `mes`,
    p33: `por`,
    p34: `habitación`,
    p35: `apartamento`,
    p36: `Detalles del plan`,
    p37: `Checkin in situ mediante QR`,
    p38: `Checkin mediante link previo envío`,
    p39: `Mensaje bienvenida email`,

    p40: `Firma de políticas biométrica`,
    p41: `Escáner de DNI - Pasaporte`,
    p42: `Almacenamiento ilimitado en la nube`,
    p43: `Tienda ventas cruzadas`,
    p44: `Envío automático de partes a autoridades`,
    p45: `Sincronización de reservas PSM`,
    p46: `Continuar`,
    
    // ----------
    
    p50:`Selecciona servicios adicionales a tu plan`,
    p51:`Recomendado`,
    p52:`WhatsApp`,
    p53:`Invitamos a realizar checkin a tus huéspedes mediante WhatsApp programado
    antes de que lleguen a tu alojamiento.`,
    p54:`apartamento`,
    p55:`villa`,
    p56:`plaza`,
    p57:`habitación`,
    p58:`Diferencia del uso de WhatsApp`,
    p59:`Usando nuestro WhatsApp`,
    p60:`Al utilizar nuestro servicio de WhatsApp, el nombre que aparecerá al enviar
    mensajes a sus huéspedes será QRCheckin. Nos encargamos de gestionar los
    mensajes para garantizar una comunicación efectiva con sus huéspedes.`,
    p61:`Si utilizan nuestro servicio con su propio número de WhatsApp, el nombre que
    aparecerá al enviar mensajes será el de su WhatsApp y podrán contar con su
    insignia de verificación. El costo de envío estará sujeto al consumo de su
    WhatsApp Business con Meta.`,
    p62:`Usando tu WhatsApp Business`,
    p63:`Valoraciones`,
    p64:`¡Aprovecha esta oportunidad para mejorar la experiencia de tus huéspedes y
    obtener más valoraciones positivas!"`,
    p65:`Beneficios`,
    p66:`Al finalizar la estancia, enviaremos a tus huéspedes un test de valoración a
    través de WhatsApp, lo que impulsará significativamente la reputación en línea
    de tu alojamiento. Nuestro servicio está diseñado para aumentar tus
    valoraciones positivas.`,
    p67:`Acceso remoto`,
    p68:`Enviamos a tus huéspedes una llave virtual para que accedan a la propiedad de
    forma autónoma.`,
    p69:`Beneficios`,
    p70:`Sus huéspedes recibirán la clave de acceso a su habitación de forma segura y
    conveniente una vez que hayan completado su checkin, brindándoles una
    experiencia sin complicaciones y mejorando la eficiencia en la gestión de
    accesos.`,
    p71:`Verificación de identidad`,
    p72:`Comprobamos la identidad de tus huéspedes mediante nuestro software de
    comparación biométrica`,
    p73:`Beneficios`,
    p74:`Comprobamos la identidad de tus huéspedes mediante nuestro software de
    comparación biométrica, asegurando que su documentación corresponde con la
    persona que accede a tu alojamiento.`,

}