import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/demos',
        name: 'Demos',
        component: () =>
            import ('@/views/Landing/demos.vue')
    },

    {

        path: '/terminos',
        name: 'Terminos',
        component: () =>
            import ('@/views/Landing/terminos.vue')

    },
    {

        path: '/politica-de-privacidad',
        name: 'pHospedaje',
        component: () =>
            import ('@/views/Landing/politicasAlojamientos.vue')

    },
    {

        path: '/politica-de-privacidad-de-alojamientos',
        name: 'pHuesped',
        component: () =>
            import ('@/views/Landing/politicasHuesped.vue')

    },



    {

        path: '/integrations',
        name: 'Integrations',
        component: () =>
            import ('@/views/Landing/integrationPage.vue')

    },
    {
        path: '/price',
        name: 'Price',
        component: () =>
            import ('@/views/Landing/pricePage.vue')
    },
    {
        path: '/check-in-online',
        name: 'checkinOnlineRoute',
        component: () =>
            import ('@/views/Landing/checkinOnlinePage.vue')
    },
    {
        path: '/legalidad',
        name: 'Legalidad',
        component: () =>
            import ('@/views/Landing/legalidadPage.vue')
    },
    {
        path: '/self-check-in',
        name: 'selfCheckinPage',
        component: () =>
            import ('@/views/Landing/selfCheckinPage.vue')
    },
    {
        path: '/upselling',
        name: 'Upselling',
        component: () =>
            import ('@/views/Landing/upsellingPage.vue')
    },
    {
        path: '/whatsapp',
        name: 'whatsappPage',
        component: () =>
            import ('@/views/Landing/whatsAppPage.vue')
    },
    {
        path: '/police',
        name: 'policePage',
        component: () =>
            import ('@/views/Landing/policePage.vue')
    },
    {
        path: '/qrcheckin',
        name: 'Qrcheckin',
        component: () =>
            import ('@/views/Landing/Qrcheckin.vue')
    },

    {
        path: '/meesage-auto',
        name: 'MensajesAutomaticos',
        component: () =>
            import ('@/views/Landing/mensajesAutomaticos.vue')
    },



]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router