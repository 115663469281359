export const home = {
    p1: 'Auto check-in',
    p2: 'Auto check-in',
    p3: 'Para hotéis e apartamentos turísticos',
    p4: 'Facilite o check-in dos seus hóspedes através de digitalização de QR ou link pelo WhatsApp',
    p5: "Facilite o check-in dos seus hóspedes através de digitalização de QR ou link pelo WhatsApp",
    // p5_5:`do seu alojamento evitando filas e impressão de papel, contribuindo para um mundo mais sustentável e verde.`,
    p6: "Experimente o plano PREMIUM gratuitamente por 14 dias",
    p7: 'Comece agora!',
    p8: 'Agende uma demonstração',
    p9: "Facilite o check-in dos seus hóspedes através de digitalização de QR ou link pelo WhatsApp",
    p10: "Rápido e fácil.",
    p11: "Para todos os tipos de Alojamentos Turísticos",
    p12: "Descubra como nos adaptamos a si",
    p13: "",
    p14: "Não haverá filas nem esperas desnecessárias no balcão de receção.",
    p15: "Os seus hóspedes poderão completar o processo de check-in diretamente a partir dos seus dispositivos móveis, a qualquer momento e em qualquer lugar que lhes seja conveniente, ou no próprio hotel digitalizando o QR.",
    p16: `COMO FUNCIONA`,
    p17: `O QRCHECKIN?`,
    p18: `O nosso processo de check-in online é rápido, simples e sem complicações`,
    p19: `Consiste em três passos simples que permitirão que os seus hóspedes realizem o seu
    registo de forma eficiente`,
    p20: `Aqui explicamos como funciona!`,
    p21: `Digitalize o QR ou link pelo WhatsApp`,
    p22: `Assim que os seus hóspedes chegarem ao hotel, encontrarão um código QR ou
    receberão um link direto para a nossa aplicação web de check-in
    online.`,
    p23: `Digitalize o Documento (BI/Passoport)`,
    p24: `Solicitaremos que digitalizem o seu documento de identificação
    válido, seja passaporte ou documento de identificação nacional.`,
    p25: `Assine as políticas do alojamento`,
    p26: `Uma vez digitalizado o documento, chegarão à etapa final do processo de
    check-in. Aqui poderão assinar eletronicamente utilizando o mesmo
    sistema.`,
    p27: ` Mensagem de boas-vindas automática, via WhatsApp`,
    p28: `Os hóspedes receberão automaticamente uma mensagem de boas-vindas personalizada,
    Você tem a oportunidade de adicionar um toque especial, personalizando a mensagem
    para cada um deles.`,
    p29: `Crie a sua conta`,
    p30: `Ofereça serviços e experiências ao WhatsApp do seu hóspede`,
    p31: `Ofereça aos seus hóspedes experiências personalizadas, serviços próprios ou
    externos, transporte, atividades de lazer… E gere mais dinheiro de cada reserva
    e mais reservas diretas!`,
    p33: `Envio automático de partes para a polícia`,
    p34: `Para a sua tranquilidade e cumprimento da legislação, o nosso sistema envia todas as noites os partes de viajantes alojados às autoridades correspondentes`,
    p35: `Reduzirá significativamente o tempo empregado pela sua equipa de
    receção.`,
    p36: `NÃO PERCA A OPORTUNIDADE`,
    p37: `Implemente o nosso sistema de check-in online e proporcione aos seus hóspedes uma
    chegada sem complicações e um serviço excepcional desde o primeiro momento
    da sua chegada.`,
    p38: `Termos e condições`,
    p39: `O nome é obrigatório`,
    p40: `O nome do hotel é obrigatório`,
    p41: `Telefone é obrigatório`,
    p42: `O Email é obrigatório`,
    p43: `Deve aceitar as políticas de privacidade`,
    p44: `Erro ao processar o pedido"`,
    p45: `Enviando mensagem..."`,
    p46: `Nome completo`,
    p47: `E-mail`,
    p48: `Telefone`,
    p49: `Escreva o que precisa`,
    p50: `Mensagem enviada com sucesso`,
    p51: `A equipa de vendas entrará em contacto o mais rápido possível`,
    p52: `Fechar`,
    p53: `Contactar`,
    p54: `O assunto é obrigatório`,
    p55: `Erro ao processar o pedido`,
    p56: `Apartamentos`,
    p57: `Villas`,
    p58: `Hotéis`,
    p59: `Parques de Campismo`,
    p60:`Envie alertas aos seus hóspedes em horários específicos pelo WhatsApp`,
    p61:`O nosso sistema de alertas permite-lhe enviar mensagens personalizadas aos seus
    hóspedes em horas específicas através do WhatsApp.`,
    p62:`Pode programar alertas para lembrá-los de eventos, passeios disponíveis de
    manhã cedo ou simplesmente enviar-lhes o menu do restaurante uma
    hora antes do almoço 😉`,
    p63:`Olá, como posso ajudar?`,


    // Correciones generales 
    p64:`o check-in`,
    p65:`dos seus hóspedes`,
    p66:`Scanner de BI - Passaporte`,
    p67:`Assinatura digital`,
    p68:`Envio para a polícia`,
    p69:`Verificação de identidade`,
    p70:`Vendas cruzadas`,
    p71:`Avaliações automáticas`,
    p72:`Experimente Gratuitamente`,
    p73:`Agilize`,
    p74:`Automatize`,
    p75:`Poupe`,

    p76:`INTEGRAMOS MAIS DE 50 GESTORES DE RESERVAS (PMS) INTEGRADOS`,

    p77:`Os dados falam por nós!`,
    p78:`Propriedades que nos utilizam`,
    p79:`Check-ins concluídos`,
    p80:`Países implementados`,


    p81:`Verificação de identidade`,
    p82:`Verificamos a identidade dos seus hóspedes através do nosso software de
    comparação biométrica, garantindo que a sua documentação corresponde à
    pessoa que acede à sua propriedade.`,

    p83:`Pagamentos Online`,
    p84:`Solicite os pagamentos online relacionados com a reserva, ofertas e experiências,
    taxas turísticas e todos os pagamentos personalizados que desejar incluir durante o
    check-in online ou durante a estadia.`,


    p85:`CONTACTE A QRCHECKIN`,
    p86:`Políticas de privacidade (Hóspede)`,
    p87:`Políticas de privacidade (Alojamento)`,

    p88:`Check-in ecológico e sem complicações! A sua chegada fácil e sem papel, cuidando
        juntos do nosso planeta.`,
    p89:`Check-in ecológico e sem complicações! cuidando juntos do nosso planeta.`,



    p90:`Processos de demonstração`,
    p91:`Check-in por QR`,
    p92:`Digitalize o QR da propriedade`,
    p93:`Check-in por WhatsApp`,
    p94:`DEMO QR`,
    p95:`Pode Digitalizar o QR para realizar o check-in de demonstração`,
    p96:`Habilitar Verificação de identidade`,
    p97:`Habilitado`,
    p98:`Desabilitado`,
    p99:`Código de segurança`,
    p100:`Com este QR podem iniciar um processo de check-in demonstrativo numa
    propriedade.`,
    p101:`Acessar`,

    p102:`Enviando link de demonstração`,
    p103:`Por favor aguarde ....`,
    p104:`Link de demonstração enviado com sucesso`,
    p105:`Receberá uma mensagem no seu WhatsApp para iniciar o processo de check-in`,
    p106:`PROCESSO DE DEMONSTRAÇÃO`,
    p107:`Digite os seus dados para receber no seu WhatsApp o nosso processo de check-in de demonstração.`,
    p108:`Habilitar Verificação de identidade`,
    p109:`Nome`,
    p110:`Email`,
    p111:`Telefone`,
    p112:`Enviar Link `,
    p113:``,
    p114:``,
    p115:``,
    p116:``,
    p117:``,
    p118:``,
    p119:``,
    p120:``,
    p121:``,

}
